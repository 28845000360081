import React from 'react';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import CategoryPicker from "../../Common/CategoryPicker";
import {FormControl} from "react-bootstrap";
import TextareaAutosize from "react-textarea-autosize";
import * as tableActions from "../../../store/actions/table";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import Button from "react-bootstrap/Button";
import {Trash} from "react-ionicons";
import * as eventActions from "../../../store/actions/events";

class JudgeTableRow extends React.Component {

    render() {

        let roundMap = Array(this.props.events.competition.competition.grade.scoring.rounds + 1).fill(1, 0);
        let {r1Rel, i, self, entry, setFieldValue, editing} = this.props;


        return (
            <tr key={r1Rel[0]?._id + i} className={(this.props.match.params.round === "3" && !r1Rel[0]?.recall) ? 'semi-transparent' : ''}>
                <td colSpan={4} width={400} data-tip={"Click to assign to another dancer"} onClick={e => {

                    this.props.setEditing([entry._id],() => {
                        let inputElement = document.getElementById(entry._id);
                        if (inputElement) {
                            inputElement.focus();
                        }
                    });

                }}>

                    {this.props.editing.indexOf(entry._id) > -1 ?
                        <Row>
                            <Col>
                                <CategoryPicker
                                    id={entry._id}
                                    items={[entry]}
                                    value={entry}
                                    tabIndex={(1 * this.props.events.competition.entries.length) + 1}
                                    itemValue={"_id"}
                                    onChange={val => {
                                        if (val) {
                                            if (this.props.events.competition.entries.filter(en => en._id === val._id).length === 0) {
                                                this.props.swapDancer(val._id, entry._id, this.props.match.params.competition, this.props.auth.token._id);
                                            } else {
                                                // This dancer already has an entry, meaning the user did not select a new value
                                                this.props.setEditing([]);
                                            }
                                        }
                                    }}
                                    remote={true}
                                    remoteKey={'dancers'}
                                    urlRoute={`/searchDancerNumber?competition=${this.props.match.params.competition}&event=${this.props.match.params.id}`}
                                    displayValue={"dancer.name"}
                                    displayValue3={""}
                                    imageKey={"image"}
                                    localState={true}
                                    displayValue2={"dancer.lname"}
                                    showDelete={false}
                                    onDelete={e => {
                                        this.props.setEditing([])
                                    }}
                                    showCancel={true}
                                    onCancel={e => {
                                        this.props.setEditing([])
                                    }}
                                    disableUpdateFetch={true}
                                    selectOnBlur={true}
                                />

                            </Col>
                        </Row>
                        :
                        <Row>
                            <Col xs={3}>
                                {entry.dancer.name} {entry.dancer.lname}
                            </Col>
                            <Col xs={3}>
                                {entry.dancer.school ? entry.dancer.school.name : ''}
                            </Col>
                            <Col xs={4}>
                                <div className={"text-center"}>
                                    <b>
                                        {entry.dancerNumber || 0}
                                    </b>
                                </div>
                            </Col>
                            <Col xs={2}>
                                <Button variant={'flat'} className={"btn-small-padding"} onClick={(e) => {

                                    e.stopPropagation();
                                    e.preventDefault();
                                    // Remove dancer from comp
                                    this.props.removeDancer(entry._id, entry.competition._id, this.props.auth.token._id, null)

                                }}><Trash fontSize={'18px'} {...{color: 'rgba(0,0,0,.5)'}} /></Button>
                            </Col>
                        </Row>
                    }
                    <Col xs={6}>
                        <div className={"text-center"}></div>
                    </Col>

                </td>
                {roundMap.map((round, roundIndex) => {

                    let roundString = (roundIndex + 1).toString();
                    let judge = parseInt(this.props.match.params.round) - 1;    // This actually works because the 'round' param is really the judge number
                     if (roundIndex === roundMap.length - 1) {
                        let rel = this.props.events.competition.template.rounds["1"].scores[judge].filter((item, index) => {
                            return item.entryCompetition === entry._id;
                        });

                            return (
                                <td  key={(1 + i) * (1 + roundIndex)} width={"600"} colSpan={2}>
                                    <Row>
                                        <Col xs={6}>
                                            <TextareaAutosize

                                                tabIndex={((roundIndex + 1))}
                                                type={"text"}
                                                className={`form-control microInput`}
                                                placeholder={"Comments"}
                                                id={rel[0] ? rel[0]._id : ''}
                                                // TODO this should probably be locked if all rounds are locked?
                                                disabled={this.props.events.competition.tabulation[`round1Locked`] ||
                                                    this.props.events.competition.tabulation[`round1SignedOff`][judge] ||
                                                    this.props.events.competition.tabulation[`round1DoubleChecked`][judge]
                                                }
                                                value={rel[0] ? rel[0]["round1Comments"] === '0' ? '' : rel[0]["round1Comments"] || '' : ''}
                                                onChange={(e) => {

                                                    this.props.updateComments(rel[0], e.target.value, judge, roundString);
                                                    setFieldValue('template', {
                                                        ...this.props.events.competition.template,
                                                        // Loop through the rounds in the rounds object and assign the new comment value whenever the correct
                                                        // id comes up. The same record exists in every round so we have to update them all or else blank/old
                                                        // values in the round 2 and 3 copies of the object will overwrite the new value when saving
                                                        rounds: Object.keys(this.props.events.competition.template.rounds).forEach((key, idx) => {
                                                            this.props.events.competition.template.rounds[key] = {
                                                                    ...this.props.events.competition.template.rounds[key],
                                                                    scores: this.props.events.competition.template.rounds[key].scores.map((score, index) => {
                                                                        return score.map(scoreItem => {
                                                                            if (scoreItem._id === rel[0]._id) {
                                                                                return {
                                                                                    ...scoreItem,
                                                                                    ["round1Comments"]: e.target.value
                                                                                }
                                                                            } else {
                                                                                return scoreItem
                                                                            }
                                                                        });
                                                                    })
                                                                }
                                                            })
                                                    })
                                                }}
                                            />

                                        </Col>

                                        <Col xs={3}>
                                            <span className={"slim"}>
                                                <div className={"text-center"}>
                                                    {rel[0] ? (rel[0]["judgeFinalIp"] < 0 ? 'NA' : rel[0]["judgeFinalIp"].toFixed(2)) : 'NA'}
                                                </div>
                                            </span>
                                        </Col>
                                        <Col xs={3}>
                                            <div className={"text-center"}>
                                                <b>
                                                    {rel[0] ? (rel[0]["judgeFinalRank"] < 0 ? 'NA' : rel[0]["judgeFinalRank"]) : 'NA'}
                                                    {rel[0] ? (rel[0]["judgeTied"] ? 'T' : '') : ''}
                                                </b>
                                            </div>

                                        </Col>

                                        </Row>
                                 </td>
                            )


                     } else {
                        let rel = this.props.events.competition.template.rounds[roundString].scores[judge] ? this.props.events.competition.template.rounds[roundString].scores[judge].filter((item, index) => {
                            return item.entryCompetition === entry._id;
                        }) : [{}];

                        let className = '';
                        let score = parseFloat(rel[0] ? rel[0][`round${roundString}Score`] : '0');

                        if (score > -1) {
                            if (score > 50) {
                                className = 'is-valid';
                            } else {
                                className = 'is-invalid';
                            }

                            if (score > 100) {
                                className = 'is-invalid';
                            }
                        }


                        return (
                            <td  key={(1 + i) * (1 + roundIndex)} colSpan={3} width={"100"}>
                                <Row>
                                    <Col xs={12}>
                                        <FormControl
                                            tabIndex={((roundIndex + 1))}
                                            type={"number"}
                                            max={100}

                                            className={`microInput ${className}`}
                                            placeholder={"Score"}
                                            id={rel[0] ? rel[0]._id : ''}
                                            disabled={this.props.events.competition.tabulation[`round${roundString}Locked`] ||
                                                this.props.events.competition.tabulation[`round${roundString}SignedOff`][judge] ||
                                                this.props.events.competition.tabulation[`round${roundString}DoubleChecked`][judge]
                                            }
                                            value={score > -1 ? score : ''}
                                            onChange={(e) => {
                                                
                                                this.props.updateScore(rel[0], parseFloat(e.target.value), judge, roundString);
                                                setFieldValue('template', {
                                                    ...this.props.events.competition.template,
                                                    rounds: {
                                                        ...this.props.events.competition.template.rounds,
                                                        [roundString]: {
                                                            ...this.props.events.competition.template.rounds[roundString],
                                                            scores: this.props.events.competition.template.rounds[roundString].scores.map((score, index) => {

                                                                return score.map(scoreItem => {
                                                                    if (scoreItem._id === rel[0]._id) {
                                                                        return {
                                                                            ...scoreItem,
                                                                            ["round" + roundString + "Score"]: e.target.value
                                                                        }
                                                                    } else {
                                                                        return scoreItem
                                                                    }
                                                                });

                                                            })
                                                        }
                                                    }
                                                })
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </td>
                        )
                    }
                })}
            </tr>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    table: state.table,
    user: state.user,
    events: state.events
});

const mapDispatchToProps = dispatch => ({
    getTableData: (view, skip, limit, sort, token, mode) => {
        dispatch(tableActions.getTableDataWithQuery(view, skip, limit, sort, {}, token, mode));
    },
    getTableDataWithQuery: (view, skip, limit, sort, query, token, mode) => {
        dispatch(tableActions.getTableDataWithQuery(view, skip, limit, sort, query, token, mode));
    },
    removeDancer: (entryCompetitionId, competition, token, cb) => {
        dispatch(eventActions.tabulatorRemoveDancer(entryCompetitionId, competition, token, cb));
    }
});

const Connected = withRouter(connect(mapStateToProps, mapDispatchToProps)(JudgeTableRow));

class JudgeTableRowContainer extends React.Component {
    render() {
        return (
                <Connected {...this.props} />

        )
    }
}

export default JudgeTableRowContainer;