import React from 'react';
import {withRouter, Prompt} from 'react-router-dom';
import {connect} from 'react-redux';
import Row from 'react-bootstrap/Row';
import Col from "react-bootstrap/Col";
import * as tableActions from '../../../store/actions/table';
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import {FormControl} from "react-bootstrap";
import Form from "react-bootstrap/Form";
import {Formik, useFormikContext} from "formik";
import * as yup from "yup";
import CategoryPicker from "../../Common/CategoryPicker";
import * as eventActions from "../../../store/actions/events";
import {ArrowForward, Checkmark} from "react-ionicons";
import TextareaAutosize from 'react-textarea-autosize';
import ReactTooltip from "react-tooltip";
import JudgeTableRow from "./JudgeTableRow";
import * as dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import humanizeDuration from "humanize-duration";
import PageLoader from "../../Common/PageLoader";
dayjs.extend(utc)

const schema = yup.object({
    email: yup.string().email("Please enter a valid email address").required("This field is required"),
    password: yup.string().min(8, 'Password needs to be at least 8 characters long'),
    password2: yup.mixed().test('match', 'Password does not match', function (password2) {
        return password2 === this.parent.password
    })
});

const PromptIfDirty = () => {
    const formik = useFormikContext();
    console.log("Judge prmopting", formik.dirty);
    return (
      <Prompt
        when={formik.dirty && formik.submitCount === 0}
        message="Are you sure you want to leave? You have with unsaved changes."
      />
    );
  };


class JudgeStandardTable extends React.Component {
    // Since the naming is horribly unclear, this is the one to use when the top tabs are judges
    constructor(props) {
        super(props);
        this.state = {
            locked: [
                false,
                false,
                false,
                false
            ],
            items: [],
            editing: [],
            timer: null
        }
    }

    componentDidMount(props) {
        ReactTooltip.rebuild();
        if (this.props.table.view !== "") {
            //this.props.getTableData("", 0, 999, {number: 1}, this.props.auth.token._id, 'add');
        }

        document.addEventListener('keydown', (e) => {
            if (e.code === "Tab") {
                // e.preventDefault()
            }
        });
    }

    componentWillUnmount() {
        if(this.state.timer) {
            clearInterval(this.state.timer);
            this.setState({timer: null});
        }
    }

    componentDidUpdate(props, state) {
        //ReactTooltip.rebuild();
        if ((this.props.table.data.length !== this.state.items.length || this.props.table.updateCount > props.table.updateCount || this.props.table.data.length !== this.state.items.length) && this.props.table.view === "") {
            this.setState({items: this.props.table.data});
        }
    }

    emptyJudge = {
        name: 'Not',
        lname: 'Selected',
        email: ''
    }

    toggleLock = () => {
        let tab = this.props.events.competition.tabulation;
        let roundsCount = this.props.events.competition.competition.grade.scoring.rounds;
        for (let i = 1; i <= roundsCount; i++) {
            tab["round" + i + "Locked"] = !tab["round" + i + "Locked"];
        }
        this.props.updateTabulationObject(tab, this.props.auth.token.token._id);
    }

    renderSelector = () => {

        return (
            <tr className={''}>
                <td colSpan={4} width={400}>
                    <Row>

                        {/* This is the empty row at the bottom of the table */}
                        <Col xs={12}>
                            <CategoryPicker
                                value={{}}
                                tabIndex={(1 * this.props.events.competition.entries.length) + 1}
                                id={"dancerSelector"}
                                itemValue={"_id"}
                                onChange={val => {

                                    if (val) {
                                        if (this.props.events.competition.entries.filter(en => en._id === val._id).length === 0) {
                                            this.props.addDancer(val._id, this.props.match.params.competition, this.props.auth.token._id, res => {});
                                        }
                                    }
                                }}
                                remote={true}
                                remoteKey={'dancers'}
                                urlRoute={`/searchDancerNumber?competition=${this.props.match.params.competition}&event=${this.props.match.params.id}`}
                                displayValue={"dancer.name"}
                                displayValue3={""}
                                imageKey={"image"}
                                localState={true}
                                displayValue2={"dancer.lname"}
                                showDelete={false}
                                disableUpdateFetch={true}

                            />
                        </Col>

                    </Row>
                </td>
                <td colSpan={12}></td>
            </tr>
        )
    }

    render() {
        let roundMap = Array(this.props.events.competition.competition.grade.scoring.rounds + 1).fill(1, 0);
        let showSignoffs = this.props.events.competition.competition.grade.judgeSignoffs;
        let roundsCount = this.props.events.competition.competition.grade.scoring.rounds;

        // Right now the judge table is only used for set comps, The parameter that this page gets as 'round' is actually the judge number
        // Since it's limited to set comps for now we will force the round to be 1
        let currRound = 1;
        let judgeNum = this.props.match.params.round;
        let doubleCheckedColSpan = roundsCount * 3;

        // Double checked property was added to tabulation object. If this is a comp that existed before that, we need to initialize it
        // TODO move this to a common location for all tabulation screens
        for (let i = 1; i <= roundsCount; i++) {
            if (!this.props.events.competition.tabulation["round" + i + "DoubleChecked"]) {
                this.props.events.competition.tabulation["round" + i + "DoubleChecked"] = [];
            }
        }

        return (
            <PageLoader loaded={!this.props.events.loading}>
                <Formik
                    validationSchema={schema}
                    onSubmit={this.handleSubmit}
                    enableReinitialize
                    initialValues={{
                        template: this.props.events.competition.template
                    }}
                >
                    {({
                        handleSubmit,
                        handleChange,
                        handleBlur,
                        values,
                        touched,
                        isValid,
                        errors,
                        isSubmitting,
                        setFieldValue
                    }) => (
                        <Form noValidate onSubmit={handleSubmit}>
                            <PromptIfDirty />

                            <div>
                                <div className={"tabulator-page-header"}>
                                    <Row>
                                        <Col xs={9}>
                                            <h4>
                                                {/* {this.props.events.competition ?
                                                    this.props.events.competition.competition ?
                                                        this.props.events.competition.competition.grade ?
                                                            this.props.events.competition.competition.grade.rounds === 1 ?
                                                                <span>
                                                            <a href={'/app/pdfoneroundresults'} target="_blank" rel="noopener noreferrer" >Print Full Results</a> | <a href={'/app/pdfoneroundsimple'} target="_blank" rel="noopener noreferrer" >Print Wall Results</a> | <a href={'/app/pdfoneroundplacing'} target="_blank" rel="noopener noreferrer" >Print Placings</a>
                                                        </span>

                                                                : null
                                                            : null
                                                        : null
                                                    : null} */}
                                            </h4>
                                        </Col>
                                        <Col className={"text-right"}>
                                            <button type="button" className="btn btn-flat"
                                                    onClick={() => this.toggleLock()}>
                                                {this.props.events.competition.tabulation ? this.props.events.competition.tabulation["round" + currRound + "Locked"] ?
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="64" height="48"
                                                            fill="currentColor" className="bi bi-lock-fill" viewBox="0 0 16 16">
                                                            <path
                                                                d="M8 1a2 2 0 0 1 2 2v4H6V3a2 2 0 0 1 2-2zm3 6V3a3 3 0 0 0-6 0v4a2 2 0 0 0-2 2v5a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2z"/>

                                                        </svg>
                                                        :
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="64" height="48"
                                                            fill="currentColor" className="bi bi-unlock-fill" viewBox="0 0 16 16">
                                                            <path
                                                                d="M11 1a2 2 0 0 0-2 2v4a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V9a2 2 0 0 1 2-2h5V3a3 3 0 0 1 6 0v4a.5.5 0 0 1-1 0V3a2 2 0 0 0-2-2z"/>
                                                        </svg>
                                                    :
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="64" height="48"
                                                        fill="currentColor" className="bi bi-unlock-fill" viewBox="0 0 16 16">
                                                        <path
                                                            d="M11 1a2 2 0 0 0-2 2v4a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V9a2 2 0 0 1 2-2h5V3a3 3 0 0 1 6 0v4a.5.5 0 0 1-1 0V3a2 2 0 0 0-2-2z"/>
                                                    </svg>
                                                }
                                            </button>
                                            <div className={"autosave"}>
                                                {this.props.events.autosaved ? this.props.events.autosaved.at ?
                                                    <span>Last Saved: {humanizeDuration((new Date().getTime()) - (new Date(this.props.events.autosaved.at).getTime()), { units: ['y', 'mo', 'w', 'd', 'h', 'm'], round: true })} ago {this.props.events.autosaved.type === 'autosave' ? `(autosave)` : ``}</span>
                                                    : null : null}
                                            </div>
                                        </Col>
                                    </Row>
                                </div>



                                <Row className={"tabulator-table-pad"}>

                                    <Table striped hover bordered className={"tab-table"}>
                                        <thead>

                                        <tr>
                                            <td colSpan={4}>

                                                <b>Dancer</b>

                                            </td>
                                            <td colSpan={doubleCheckedColSpan}>
                                                <Row>
                                                    <Col xs={12}>
                                                        <span className={"tab-table-double-checked-span"}><b>Double Checked</b></span>
                                                        <FormControl
                                                            disabled={this.props.locked}
                                                            type="checkbox"
                                                            name="doubleChecked"
                                                            checked={this.props.events.competition.tabulation ? this.props.events.competition.tabulation[
                                                                `round${roundsCount}DoubleChecked`][parseInt(judgeNum) - 1] : false}
                                                            onChange={val => {
                                                                let tab = this.props.events.competition.tabulation;
                                                                tab["round1DoubleChecked"][parseInt(judgeNum) - 1] = !tab["round1DoubleChecked"][parseInt(judgeNum) - 1]
                                                                tab["round2DoubleChecked"][parseInt(judgeNum) - 1] = !tab["round2DoubleChecked"][parseInt(judgeNum) - 1]
                                                                tab["round3DoubleChecked"][parseInt(judgeNum) - 1] = !tab["round3DoubleChecked"][parseInt(judgeNum) - 1]

                                                                this.props.updateTabulationObject(tab, this.props.auth.token.token._id);
                                                            }}
                                                            className={"no-width tab-table-double-checked"}
                                                        />
                                                    </Col>
                                                </Row>
                                            </td>
                                            {showSignoffs ?
                                                <React.Fragment>
                                                <td colSpan={4}>
                                                    <div className={"tab-table-signoff-left"}>
                                                        <Col xs={9}>
                                                        <span className={'float-left  pr-1'}>
                                                            <b>
                                                            Recall Signed off?
                                                            <br /> (
                                                            <a
                                                                href={`/app/pdf/2/${judgeNum - 1}/rank`}
                                                                target="_blank"
                                                                rel="noopener noreferrer">
                                                                Rank
                                                            </a>
                                                            ,{' '}
                                                            <a
                                                                href={`/app/pdf/2/${judgeNum - 1}/dancer`}
                                                                target="_blank"
                                                                rel="noopener noreferrer">
                                                                Dancer
                                                            </a>
                                                            )
                                                            </b>
                                                        </span>
                                                        </Col>
                                                        <Form.Check
                                                            checked={this.props.events.competition.tabulation[
                                                            `round1SignedOff`][judgeNum - 1]
                                                            }
                                                            className={'no-width tab-table-signedoff'}
                                                            name={`signedoff${this.props.match.params.round}${judgeNum - 1}`}
                                                            onChange={(e) => {
                                                                const target = e.target;
                                                                const checked = target.checked;
                                                                const name = target.name;
                                                                //  Mark signed off for this judge for first 2 rounds (pre-recall)
                                                                this.props.events.competition.tabulation[
                                                                    `round1SignedOff`][judgeNum - 1] = checked;
                                                                this.props.events.competition.tabulation[
                                                                    `round2SignedOff`][judgeNum - 1] = checked;
                                                                        
                                                                this.setState({
                                                                    [name]: checked,
                                                                });
                                                            }}
                                                            disabled={this.props.locked}/>
                                                    </div>
                                                    <div className={"tab-table-signoff-right"}>
                                                    <Col xs={9}>
                                                    <span className={'float-left  pr-1'}>
                                                        <b>
                                                        Signed off?
                                                        <br /> (
                                                        <a
                                                            href={`/app/pdf/${roundsCount}/${judgeNum - 1}/rank`}
                                                            target="_blank"
                                                            rel="noopener noreferrer">
                                                            Rank
                                                        </a>
                                                        ,{' '}
                                                        <a
                                                            href={`/app/pdf/${roundsCount}/${judgeNum - 1}/dancer`}
                                                            target="_blank"
                                                            rel="noopener noreferrer">
                                                            Dancer
                                                        </a>
                                                        )
                                                        </b>
                                                    </span>
                                                    </Col>
                                                    <Form.Check
                                                        checked={this.props.events.competition.tabulation[
                                                        `round${roundsCount}SignedOff`][judgeNum - 1]
                                                        }
                                                        className={'no-width tab-table-signedoff'}
                                                        name={`signedoff${this.props.match.params.round}${judgeNum - 1}`}
                                                        onChange={(e) => {
                                                            const target = e.target;
                                                            const checked = target.checked;
                                                            const name = target.name;
                                                            //  Mark signed off for this judge for all rounds (this is for Set comps only)
                                                            for (let x = 1; x <= roundsCount; x++) {
                                                                this.props.events.competition.tabulation[
                                                                    `round${x}SignedOff`][judgeNum - 1] = checked;
                                                            }
                                                            this.setState({
                                                                [name]: checked,
                                                            });
                                                        }}
                                                        disabled={this.props.locked}/>
                                                    </div>
                                                </td>
                                                </React.Fragment>
                                                : null
                                            }
                                        </tr>

                                        </thead>
                                        <tbody>
                                        <tr>
                                            <td colSpan={4}></td>

                                            <td key={judgeNum} colSpan={9}>
                                                <Row>
                                                    <Col xs={9}>
                                                        <CategoryPicker
                                                            value={this.props.events.competition.template['rounds'][(currRound).toString()].judges[judgeNum - 1] ? this.props.events.competition.template['rounds'][(currRound).toString()].judges[parseInt(judgeNum) - 1] : {}}
                                                            itemValue={"_id"}
                                                            onChange={val => {
                                                                // TODO: This changes the component from controlled to uncontrolled, need to fix that
                                                                // I believe we need to call setFieldValue
                                                                this.props.updateStandardJudge(currRound, judgeNum - 1, val);
                                                            }}
                                                            remote={true}
                                                            remoteKey={'users'}
                                                            urlRoute={"/search"}
                                                            displayValue={"name"}
                                                            displayValue3={"email"}
                                                            imageKey={"image"}
                                                            localState={true}
                                                            displayValue2={"lname"}
                                                            showDelete={false}


                                                        />
                                                    </Col>
                                                    <Col xs={3}>
                                                        <FormControl
                                                            type={"text"}

                                                            className={"microInput"}
                                                            placeholder={"Label"}
                                                            disabled={this.props.events.competition.tabulation[`round${currRound}Locked`] ||
                                                                this.props.events.competition.tabulation[`round${currRound}SignedOff`][judgeNum - 1] ||
                                                                this.props.events.competition.tabulation[`round${currRound}DoubleChecked`][judgeNum - 1]}
                                                            value={this.props.events.competition.template.rounds[currRound.toString()].judges[judgeNum - 1] ? this.props.events.competition.template.rounds[currRound.toString()].judges[judgeNum - 1].label : ''}
                                                            onChange={(e) => {
                                                                this.props.updateJudgeLabel(currRound, judgeNum - 1, e.target.value);
                                                            }}
                                                        />
                                                    </Col>
                                                </Row>
                                            </td>
                                            <td></td>
                                        </tr>

                                        <tr>
                                            <td colSpan={4}>
                                                <Row>
                                                    <Col xs={3}>
                                                        <b>Name</b>

                                                    </Col>
                                                    <Col xs={3}>
                                                        <b>School</b>

                                                    </Col>
                                                    <Col xs={4}>
                                                        <div className={"text-center"}>
                                                            <b>
                                                                <b>Number</b>
                                                            </b>
                                                        </div>


                                                    </Col>
                                                    <Col xs={2}>
                                                    </Col>
                                                </Row>
                                            </td>
                                            {roundMap.map((n, ni) => {
                                                if (ni === roundMap.length - 1) {
                                                    return (
                                                        <td key={ni} width={300} colSpan={2}>
                                                            <Row>
                                                                <Col xs={6}>
                                                                    <div className={"text-center"}>
                                                                        <b>
                                                                            <b>Comments</b>
                                                                        </b>
                                                                    </div>

                                                                </Col>
                                                                <Col xs={3}>
                                                                    <div className={"text-center"}>
                                                                        <b>
                                                                            <b>Total IP</b>
                                                                        </b>
                                                                    </div>

                                                                </Col>
                                                                <Col xs={3}>
                                                                    <div className={"text-center"}>
                                                                        <b>
                                                                            <b>Total Rank</b>
                                                                        </b>
                                                                    </div>

                                                                </Col>

                                                            </Row></td>
                                                    )
                                                } else {
                                                    return (
                                                        <td key={ni} colSpan={3} width={100}>
                                                            <Row>
                                                                <Col xs={12}>
                                                                    <b>R{ni + 1} Score</b><br />
                                                                    {/* <a href={`/app/pdfoneroundsimple/${ni + 1}/${parseInt(judgeNum)}`} target="_blank" rel="noopener noreferrer" >Rank</a> */}
                                                                </Col>
                                                            </Row>
                                                        </td>
                                                    )
                                                }

                                            })}
                                        </tr>



                                        {this.props.events.competition ?
                                            this.props.events.competition.entries ?
                                                this.props.events.competition.entries.map ?
                                                    this.props.events.competition.entries.map((entry, i) => {

                                                        let r1Rel = this.props.events.competition.template.rounds["1"].scores[judgeNum - 1].filter((item, index) => {
                                                            return item.entryCompetition === entry._id;
                                                        });
                                                        let self = this;

                                                        return (
                                                            <JudgeTableRow
                                                                r1Rel={r1Rel}
                                                                self={self}
                                                                i={i}
                                                                entry={entry}
                                                                setFieldValue={setFieldValue}
                                                                touched={touched}
                                                                editing={this.state.editing}
                                                                setEditing={(val, cb) => {
                                                                    this.setState({editing: val}, cb)}
                                                                }
                                                                updateScore={this.props.updateScore}
                                                                updateComments={this.props.updateComments}
                                                                swapDancer={this.props.swapDancer}
                                                            />
                                                        )
                                                    })
                                                    : null
                                                : null
                                            : null}

                                        {this.renderSelector()}

                                        </tbody>
                                    </Table>
                                </Row>
                                <div className={"text-right ex-pad"}>
                                    <Button variant={"flat"} className={"btn-raised"} onClick={e => {
                                        e.preventDefault();

                                        let invalidCount = document.getElementsByClassName('is-invalid').length;


                                        this.props.updateTabScore(
                                            this.props.events.competition.template, 
                                            this.props.events.competition.tabulation,
                                            this.props.auth.token._id,
                                            false,
                                            this.props.events.competition.competition._id,
                                            this.props.events.competition.competition.grade.rounds);
                                    }}><Checkmark /> Save</Button> &nbsp;
                                    <Button variant={"primary"} onClick={e => {
                                        e.preventDefault();

                                        this.props.updateTabScore(
                                            this.props.events.competition.template, 
                                            this.props.events.competition.tabulation, 
                                            this.props.auth.token._id,
                                            false, 
                                            this.props.events.competition.competition._id,
                                            this.props.events.competition.competition.grade.rounds,
                                            res => {
                                            if (res.data.status === 200) {
                                                if (parseInt(judgeNum) < this.props.events.competition.competition.grade.scoring.judge_count[0]) {
                                                    // If the current judge number is less than the total judges, show the next judge
                                                    this.props.history.push(`/app/tabulation/${this.props.match.params.id}/${this.props.match.params.competition}/${parseInt(judgeNum) + 1}`)
                                                } else {
                                                    // Otherwise show the overview
                                                    this.props.history.push(`/app/tabulation/${this.props.match.params.id}/${this.props.match.params.competition}/overview`)

                                                }
                                            }
                                        });
                                    }}>Save & Next &nbsp; &nbsp;<ArrowForward /></Button>
                                </div>

                            </div>
                        </Form>
                    )}
                </Formik>
            </PageLoader>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    table: state.table,
    user: state.user,
    events: state.events,
    single: state.single,
    tabulator: state.tabulator
});

const mapDispatchToProps = dispatch => ({
    getTableData: (view, skip, limit, sort, token, mode) => {
        dispatch(tableActions.getTableDataWithQuery(view, skip, limit, sort, {}, token, mode));
    },
    getTableDataWithQuery: (view, skip, limit, sort, query, token, mode) => {
        dispatch(tableActions.getTableDataWithQuery(view, skip, limit, sort, query, token, mode));
    },
    updateTabScore: (template, tabulation, token, showAutosave, competitionId, rounds, cb) => {
        dispatch(eventActions.updateTabulatorScore(template, tabulation, token, showAutosave, competitionId, rounds, cb));
    },
    updateScore: (entry, value, judgeIndex, round) => {
        dispatch(eventActions.updateScore(entry, value, judgeIndex, round));
    },
    updateComments: (entry, value, judgeIndex, round) => {
        dispatch(eventActions.updateComments(entry, value, judgeIndex, round));
    },
    updateJudge: (round, judge, val) => {
        dispatch(eventActions.updateJudge(round, judge, val));
    },
    updateStandardJudge: (round, judge, val) => {
        dispatch(eventActions.updateStandardJudge(round, judge, val));
    },
    updateStandardScore: (entry, value, round, judgeIndex) => {
        round = (parseInt(judgeIndex)).toString();
        judgeIndex = parseInt(round) - 1;
        dispatch(eventActions.updateScore(entry, value, judgeIndex, round));
    },
    updateJudgeLabel: (round, judge, val) => {
        dispatch(eventActions.updateJudgeLabel(round, judge, val));
    },
    updateTabulationObject: (tab, token) => {
        dispatch(eventActions.updateTabulationObject(tab, token));
    },
    updateEntryScore: (entry, round, key, val) => {
        dispatch(eventActions.updateEntryScore(entry, round, key, val));
    },
    addDancer: (id, competition, token, cb) => {
        dispatch(eventActions.tabulatorAddDancer(id, competition, token, cb));
    },
    swapDancer: (newId, oldId, competition, token, cb) => {
        dispatch(eventActions.tabulatorSwapDancer(newId, oldId, competition, token, cb));
    }
});

const Connected = withRouter(connect(mapStateToProps, mapDispatchToProps)(JudgeStandardTable));

class JudgeStandardTableContainer extends React.Component {
    render() {
        return (
            <div>
                <Connected {...this.props} />
            </div>
        )
    }
}

export default JudgeStandardTableContainer;
