import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';

import urls from '../../../../utils/urls';
import placeholder from '../../../../assets/images/placeholder-person.png';
import * as singleActions from '../../../../store/actions/single';
import EntrySteps from '../EntrySteps';
import EntryPay from '../EntryPay';
import EntryCompetitions from '../EntryCompetitions';
import EntryUpload from '../EntryUpload';
import EntryInPerson from '../EntryInPerson';
import SingleEntryEditButton from './SingleEntryEditButton';

class SingleEntry extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      fileSelectField: '',
      filePickerOpen: false,
      selectingFileType: 'image',
      preview: '',
      image: '',
      createModerator: '',
      createAdmin: ''
    };
  }

  handleFormModal = (e) => {
    this.setState({ filePickerOpen: false });
  };

  selectFile = (file) => {
    this.props.setValues({ image: file });
  };

  componentDidMount() {
    let loadBlank = false;

    if (this.props.match.params?.view === 'register') {
      loadBlank = true;
    }

    if (loadBlank) {
      this.props.loadEntryData(this.props.match.params.id, this.props.auth.token._id);
    } else {
      this.props.get(this.props.match.params.id, this.props.auth.token._id);
    }
  }

  componentDidUpdate(props) {
    if (this.props.single.event && !this.state.preview) {
      if (this.props.single.event.image) {
        if (this.props.single.event.image._id) {
          this.setState({
            preview: urls.getS3Url(
              this.props.single.event.image.thumb || this.props.single.event.image.path
            )
          });
        }
      }
    }
  }

  handleSubmit = (values) => {
    let view = 'schools';

    let data = new FormData();
    data.append('files', this.state.image);

    let params = { school: values };
    params.school.admins = values.admins.map((item) => item._id);
    params.school.moderators = values.moderators.map((item) => item._id);

    if (this.state.createAdmin) {
      params.createAdmin = this.state.createAdmin;
    }

    if (this.state.createModerator) {
      params.createModerator = this.state.createModerator;
    }

    if (this.props.single.single._id) {
      params.school._id = this.props.single.single._id;
    }

    data.set('data', JSON.stringify(params));

    if (params._id) {
      this.props.patch(view, params._id, data, this.props.auth.token._id);
    } else {
      if (this.props.onboarding) {
        this.props.postOnboarding('users/nextStep', data, this.props.auth.token._id, (id) => {
          this.setState({ disableNext: false });
        });
      } else {
        this.props.post(view, data, this.props.auth.token._id, (id) => {
          this.props.history.push('/admin/schools/' + id);
        });
      }
    }
  };

  onEditHandler = () => {
    const sections = this.props.single.single.sections.map((outerSection) => {
      return {
        ...outerSection,
        sections: outerSection.sections.map((innerSection) => {
          return {
            ...innerSection,
            dances: innerSection.dances.map((dance) => {
              const entering = !isEmpty(dance.selected.gradeId);
              let options = entering
                ? dance.options.map((option) => {
                    return option.price
                      ? {
                          ...option,
                          price: 0
                        }
                      : option;
                  })
                : dance.options;
              if (!options.some((o) => o.gradeId === '')) {
                options.unshift({
                  gradeId: '',
                  gradeName: 'Not Entering',
                  comp: null,
                  rounds: 0,
                  price: 0
                });
              }
              return {
                ...dance,
                options
              };
            })
          };
        })
      };
    });
    this.props.setSingleEntry({
      step: 0,
      sections: sections,
      state: 'edit',
      price: 0
    });
  };

  render() {
    let regEnd = this.props.single.event?.registrationEnd;
    let regEndDate = new Date(regEnd);
    let currDate = new Date();
    let showWrench = (this.props.user.user.role === "admin") || currDate <= regEndDate;
    return (
      <div>
        {this.props.single.single ? (
          !isNaN(this.props.single.single.step) ? (
            <div>
              {this.props.single.event ? (
                <div className={'entry-title'}>
                  {this.state.preview ? (
                    <div
                      onClick={(e) => this.setState({ filePickerOpen: true })}
                      className={'artist-img avatar square'}
                      style={{
                        background: this.state.preview
                          ? `url(${this.state.preview}), linear-gradient(0deg, rgba(0,0,0,.6), rgba(0,0,0,.4), rgba(0,0,0,.2), transparent)`
                          : `url(${placeholder}), linear-gradient(0deg, rgba(0,0,0,.6), rgba(0,0,0,.4), rgba(0,0,0,.2), transparent)`
                      }}></div>
                  ) : null}
                  <span className={'event-title'}>{this.props.single.event.name}</span>
                  {this.props.single.single.step === 2 && (
                    <SingleEntryEditButton
                      show={showWrench}
                      click={this.onEditHandler}
                      className="float-right"
                    />
                  )}
                </div>
              ) : null}
              <EntrySteps />

              <div
                className={`event-container ${this.props.user.onboardingLoading ? 'loading' : ''}`}>
                {this.props.single.single.step === 0 ? (
                  <div className={''}>
                    <EntryCompetitions />
                  </div>
                ) : null}

                {this.props.single.single.step === 1 ? (
                  <div className={'elevated rounded show-overflow'}>
                    <EntryPay />
                  </div>
                ) : null}

                {this.props.single.single.step === 2 ? (
                  this.props.single.single ? (
                    this.props.single.single.inPerson ? (
                      <EntryInPerson />
                    ) : (
                      <EntryUpload />
                    )
                  ) : null
                ) : null}
              </div>
            </div>
          ) : null
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  single: state.single,
  user: state.user
});

const mapDispatchToProps = (dispatch) => ({
  post: (view, data, token, redirect) => {
    dispatch(singleActions.postFormData(view, data, token, true, redirect));
  },
  postOnboarding: (view, data, token, redirect) => {
    dispatch(singleActions.postNextStep(view, data, token, true, redirect));
  },
  patch: (view, id, data, token) => {
    dispatch(singleActions.patch(view, id, data, token));
  },
  get: (id, token) => {
    dispatch(singleActions.getMultiSingle('entry', 'entries', id, token));
  },
  setSingleEntry: (entry) => {
    dispatch(singleActions.setSingleEntry(entry));
  },
  loadEntryData: (eventId, token) => {
    dispatch(singleActions.loadEntryData(eventId, token));
  },
  loadEntryPay: (token) => {
    dispatch(singleActions.loadEntryPay(token));
  }
});

const Connected = withRouter(connect(mapStateToProps, mapDispatchToProps)(SingleEntry));

class SingleEntryContainer extends React.Component {
  render() {
    return (
      <div>
        <Connected {...this.props} />
      </div>
    );
  }
}

export default SingleEntryContainer;
