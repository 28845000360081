/* Copyright (C) Nick Germaine - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Nick Germaine <nickgermaine1024@gmail.com>, 1/29/2021
 */

const defaultState = {
  data: [],
  tableLoading: false,
  tableCount: 0,
  tableSorted: {},
  view: '',
  subView: '',
  updateCount: 0,
  dancers: [],
  events: [],
  entries: [],
  event: {},
  competitions: [],
  organizerSchool: {},
  results: [],
  stats: {},
  schoolEntries: [],
  autosaved: {
    at: null,
    status: true,
    type: ''
  },
  competition: {
    entries: [],
    single: {},
    event: {},
    competition: {},
    template: {
      rounds: {
        1: {
          judges: [{}, {}, {}, {}, {}],
          scores: [[], [], [], [], []]
        },
        2: {
          judges: [{}, {}, {}, {}, {}],
          scores: [[], [], [], [], []]
        },
        3: {
          judges: [{}, {}, {}, {}, {}],
          scores: [[], [], [], [], []]
        }
      }
    }
  },
  organizerCompetition: {
    judgeEvents: []
  },
  template: {},
  mergeResponse: '',
  splitResponse: ''
};

const irishPoints = [
  100, 75, 65, 60, 56, 53, 50, 47, 45, 43, 41, 39, 38, 37, 36, 35, 34, 33, 32, 31, 30, 29, 28, 27,
  26, 25, 24, 23, 22, 21, 20, 19, 18, 17, 16, 15, 14, 13, 12, 11, 10, 9, 8, 7, 6, 5, 4, 3, 2, 1
];

function addScore(template, ro, state, entry, judgeIndex, val) {
  if (val > 100) {
    val = 100;
  }
  val = parseFloat(val);
  for (let i = 0; i < template.rounds[ro].scores[judgeIndex].length; i++) {
    let item = template.rounds[ro].scores[judgeIndex][i];
    if (item._id === entry._id) {
      template.rounds[ro].scores[judgeIndex][i]['round' + ro + 'Score'] = val;

      for (let r = 0; r < state.competition.competition.grade.rounds; r++) {
        let round = (r + 1).toString();
        // I can't wait to get rid of this. We have to do this because the records for the other rounds might be the same record
        // i.e. all 3 are the same record for a set panel and the records for rounds 1 and 2 are the same for split
        // since there may be different judge counts per round we need to make sure the array even exists
        if (template.rounds[round].scores[judgeIndex]) {
          for (let i = 0; i < template.rounds[round].scores[judgeIndex].length; i++) {
            let item = template.rounds[round].scores[judgeIndex][i];
            if (item.entryCompetition === entry.entryCompetition) {
              template.rounds[round].scores[judgeIndex][i]['round' + ro + 'Score'] = val;
            }
          }
        }
      }
    }
  }

  return template;
}

function assignJudgeRoundRanks(template, ro, judgeIndex) {
  let scores = JSON.parse(JSON.stringify(template.rounds[ro].scores[judgeIndex]));
  let sorted = scores.sort(
    (a, b) => parseFloat(b[`round${ro}Score`]) - parseFloat(a[`round${ro}Score`])
  );
  let ind = 0;
  for (let entry of sorted) {
    let i = sorted.filter((rel) => rel[`round${ro}Score`]).indexOf(entry);
    if (parseInt(ro) === 2) {
      // i = sorted.filter(rel => rel["round1Score"] + rel["round2Score"]).indexOf(entry);
    }

    entry[`round${ro}Rank`] = i + 1;

    if (i > 0) {
      if (entry[`round${ro}Score`] === sorted[i - 1][`round${ro}Score`]) {
        entry[`round${ro}Rank`] = sorted[i - 1][`round${ro}Rank`];
      }
    }

    let rel = template.rounds[ro].scores[judgeIndex].filter((item) => item._id === entry._id);
    if (rel[0]) {
      rel[0][`round${ro}Rank`] = entry[`round${ro}Rank`];
    }

    let totalScore = entry[`round${ro}Score`];
    let firstAppearance = -1;
    let ties = sorted.filter((rel2, index) => {
      if (rel2[`round${ro}Score`] === rel[0][`round${ro}Score`] && firstAppearance === -1) {
        firstAppearance = index;
      }
      return rel2[`round${ro}Score`] === totalScore;
    });
    entry[`round${ro}Rank`] = firstAppearance + 1;

    // console.log("TIES", ties);
    //console.debug("FINALL SCORE", irishPoints[firstAppearance]);

    if (ties.length > 0) {
      let tiePoints = [];
      let tieSum = 0;
      let firstPoint = firstAppearance;
      for (let o = 0; o < ties.length; o++) {
        tiePoints.push(irishPoints[firstPoint + o]);
        tieSum += irishPoints[firstPoint + o];
      }
      //console.debug("TIE POINTS", tieSum, tiePoints);
      rel[0][`round${ro}Ip`] = parseFloat((tieSum / tiePoints.length).toFixed(2));
    } else {
      rel[0][`round${ro}Ip`] = parseFloat(irishPoints[ind]);
    }
  }

  return template;
}

function assignFullRoundScores(template, ro, judgeIndex) {
  let scores = JSON.parse(JSON.stringify(template.rounds[ro].scores[judgeIndex]));
  let sorted = scores.sort(
    (a, b) => parseFloat(b[`round${ro}Score`]) - parseFloat(a[`round${ro}Score`])
  );
  let entries = [];

  for (let i = 0; i < sorted.length; i++) {
    let item = sorted[i];
    let otherJudges = JSON.parse(JSON.stringify(template.rounds[ro].scores));
    let otherRoundScores = [];

    for (let o = 0; o < template.rounds[ro].scores[judgeIndex].length; o++) {
      let totalScore = 0;
      let totalIp = 0;
      let item2 = template.rounds[ro].scores[judgeIndex][o];

      for (let j = 0; j < otherJudges.length; j++) {
        for (let s = 0; s < otherJudges[j].length; s++) {
          let oItem = otherJudges[j][s];

          if (oItem.entryCompetition === item.entryCompetition) {
            //console.log("ITEM" ,oItem.entryCompetition === item.entryCompetition);
            //console.log("Adding", oItem["round" + oItem.round.toString() + "Score"], "to", totalScore);

            if (oItem['round' + ro + 'Score']) {
              totalScore += parseFloat(oItem['round' + ro + 'Score']);
            }
            if (oItem['round' + ro + 'Score']) {
              totalIp += parseFloat(oItem['round' + ro + 'Ip']);
            }
            let exists = false;

            let ent = entries.filter((it) => it.entryCompetition === oItem.entryCompetition);
            ///console.log("En", ent);
            if (ent.length === 0) {
              entries.push(oItem);
            } else {
              let ind = 0;
              let ent = entries.filter((it, entryIndex) => {
                if (it.entryCompetition === oItem.entryCompetition) {
                  ind = entryIndex;
                }
              });
              //console.log("Exists at index", ind, totalScore);
              entries[ind]['totalRound' + ro + 'Score'] = totalScore;
              item2['totalRound' + ro + 'Score'] = totalScore;

              entries[ind]['totalRound' + ro + 'Ip'] = totalIp;
              item2['totalRound' + ro + 'Ip'] = totalIp;
            }
          }
        }
      }

      if (item.entryCompetition === item2.entryCompetition) {
        //template.rounds[ro].scores[action.payload.judgeIndex][o]["totalRound" + ro + "Score"] = totalScore;
        item2['totalRound' + ro + 'Score'] = totalScore;
      }
    }
  }

  return entries;
}

function assignRound2Finals(template, state) {}

function assignFinals(template, state, entries) {
  for (const entry of entries) {
    let finals = {
      finalRound1Ip: 0,
      finalRound2Ip: 0,
      finalRound3Ip: 0,
      finalScore: 0,
      finalIp: 0,
      finalRank: 0,
      finalTwoRoundIp: 0,
      finalTwoRoundRank: 0
    };

    for (let r = 0; r < state.competition.competition.grade.rounds; r++) {
      let relevant = template.rounds[(r + 1).toString()].scores[0].filter(
        (it) => it.entryCompetition === entry.entryCompetition
      );
      for (const rel in relevant) {
        finals[`finalRound${(r + 1).toString()}Ip`] +=
          relevant[rel][`totalRound${(r + 1).toString()}Ip`];
        finals[`finalIp`] += relevant[rel][`totalRound${(r + 1).toString()}Ip`];
        if (r < 2) {
          if (!isNaN(relevant[rel][`totalRound${(r + 1).toString()}Ip`])) {
            finals[`finalTwoRoundIp`] += parseFloat(
              relevant[rel][`totalRound${(r + 1).toString()}Ip`]
            );
          }
        }
      }
    }

    for (let r = 0; r < state.competition.competition.grade.rounds; r++) {
      for (let j = 0; j < state.competition.competition.grade.judges / 3; j++) {
        let relevant = template.rounds[(r + 1).toString()].scores[j].filter(
          (it) => it.entryCompetition === entry.entryCompetition
        );

        for (let rel in relevant) {
          relevant[rel][`finalIp`] = finals[`finalIp`];
          relevant[rel][`finalTwoRoundIp`] = finals[`finalTwoRoundIp`];
          relevant[rel][`finalRound${(r + 1).toString()}Ip`] =
            finals[`totalRound${(r + 1).toString()}Ip`];
        }
      }
    }
    // console.log("ENTRY", entry);
    //console.log("FINALS", finals);
  }

  return template;
}

function assignFinalRoundRank(template, ro, judgeIndex, state) {
  //console.log("S", template.rounds[ro].scores);
  let scores = JSON.parse(JSON.stringify(template.rounds[ro].scores[0]));
  let sorted = scores.sort((a, b) => parseFloat(b[`finalIp`]) - parseFloat(a[`finalIp`]));
  let ind = 0;
  for (let entry of sorted) {
    let i = sorted.filter((rel) => rel[`finalIp`]).indexOf(entry);
    if (parseInt(ro) === 2) {
      //i = sorted.filter(rel => rel["finalTwoRoundIp"]).indexOf(entry);
    }

    entry[`finalRank`] = i + 1;

    let allRel = [];
    for (let r = 0; r < state.competition.competition.grade.rounds; r++) {
      for (let j = 0; j < state.competition.competition.grade.judges / 3; j++) {
        for (let it = 0; it < template.rounds[(r + 1).toString()].scores[j].length; it++) {
          let item = template.rounds[(r + 1).toString()].scores[j][it];
          if (item.entryCompetition === entry.entryCompetition) {
            allRel.push(item);
          }
        }
      }
    }

    if (i > 0) {
      if (entry[`finalIp`] === sorted[i - 1][`finalIp`]) {
        entry[`finalRank`] = sorted[i - 1][`finalRank`];
      }
    }

    let rel = template.rounds[ro].scores[0].filter((item) => item._id === entry._id);
    if (rel[0]) {
      rel[0][`finalRank`] = entry[`finalRank`];
    }

    let totalScore = entry[`finalIp`];
    let firstAppearance = -1;
    let ties = sorted.filter((rel2, index) => {
      //console.log("REL@", rel2, rel[0]);
      if (rel2[`finalIp`] === rel[0][`finalIp`] && firstAppearance === -1) {
        firstAppearance = index;
      }
      return rel2[`finalIp`] === totalScore;
    });
    rel[0][`finalRank`] = firstAppearance + 1;

    if (ties.length > 0) {
      let tiePoints = [];
      let tieSum = 0;
      let firstPoint = firstAppearance;
      for (let o = 0; o < ties.length; o++) {
        tiePoints.push(irishPoints[firstPoint + o]);
        tieSum += irishPoints[firstPoint + o];
      }
      //console.debug("TIE POINTS", tieSum, tiePoints);
      //rel[0][`totalRound${ro}Ip`] = (tieSum / tiePoints.length).toFixed(2);
      rel[0][`finalRank`] = firstAppearance + 1;
    } else {
      //rel[0][`totalRound${ro}Ip`]  = irishPoints[ind];
      rel[0][`finalRank`] = ind + 1;
    }

    for (let ar of allRel) {
      ar[`finalRank`] = rel[0][`finalRank`];
    }

    ind++;
  }

  return template;
}

function assignRecall(template, ro, judgeIndex, state) {
  let scores = JSON.parse(JSON.stringify(template.rounds['2'].scores[0]));
  let sorted = scores.sort(
    (a, b) => parseFloat(a[`totalTwoRoundRank`]) - parseFloat(b[`totalTwoRoundRank`])
  );

  let highest = sorted[0].totalRound2Rank;
  let lowest = sorted[sorted.length - 1].totalRound2Rank;
  let count = sorted.length;
  let lowestRankAccepted = Math.ceil(lowest * 0.5);

  if (count > 20) {
    let ind = 0;
    for (let entry of sorted) {
      if (ind < 10) {
        let allRel = [];
        for (let r = 0; r < state.competition.competition.grade.rounds; r++) {
          for (let j = 0; j < state.competition.competition.grade.judges / 3; j++) {
            for (let it = 0; it < template.rounds[(r + 1).toString()].scores[j].length; it++) {
              let item = template.rounds[(r + 1).toString()].scores[j][it];
              if (item.entryCompetition === entry.entryCompetition) {
                allRel.push(item);
              }
            }
          }
        }

        for (let ar of allRel) {
          ar[`recall`] = true;
        }
      } else {
        let allRel = [];
        for (let r = 0; r < state.competition.competition.grade.rounds; r++) {
          for (let j = 0; j < state.competition.competition.grade.judges / 3; j++) {
            for (let it = 0; it < template.rounds[(r + 1).toString()].scores[j].length; it++) {
              let item = template.rounds[(r + 1).toString()].scores[j][it];
              if (item.entryCompetition === entry.entryCompetition) {
                allRel.push(item);
              }
            }
          }
        }

        for (let ar of allRel) {
          ar[`recall`] = true;
        }
      }
      ind++;
    }
  } else {
    let ind = 0;
    for (let entry of sorted) {
      if (entry['totalTwoRoundRank'] <= lowestRankAccepted) {
        let allRel = [];
        for (let r = 0; r < state.competition.competition.grade.rounds; r++) {
          for (let j = 0; j < state.competition.competition.grade.judges / 3; j++) {
            for (let it = 0; it < template.rounds[(r + 1).toString()].scores[j].length; it++) {
              let item = template.rounds[(r + 1).toString()].scores[j][it];
              if (item.entryCompetition === entry.entryCompetition) {
                allRel.push(item);
              }
            }
          }
        }

        for (let ar of allRel) {
          ar[`recall`] = true;
        }
      } else {
        let allRel = [];
        for (let r = 0; r < state.competition.competition.grade.rounds; r++) {
          for (let j = 0; j < state.competition.competition.grade.judges / 3; j++) {
            for (let it = 0; it < template.rounds[(r + 1).toString()].scores[j].length; it++) {
              let item = template.rounds[(r + 1).toString()].scores[j][it];
              if (item.entryCompetition === entry.entryCompetition) {
                allRel.push(item);
              }
            }
          }
        }

        for (let ar of allRel) {
          ar[`recall`] = true;
        }
      }
      ind++;
    }
  }

  return template;
}

function assignNq(template, ro, judgeIndex, state) {
  let scores = JSON.parse(JSON.stringify(template.rounds['3'].scores[judgeIndex]));
  let sorted = scores.sort((a, b) => parseFloat(a[`finalRank`]) - parseFloat(b[`finalRank`]));

  //let lowest = sorted[sorted.length - 1].finalRank;
  let count = sorted.length;

  let highestIndex = Math.ceil(count * 0.25) + 10;

  let ind = 0;
  for (let entry of sorted) {
    if (ind <= highestIndex && entry['recall']) {
      let allRel = [];
      for (let r = 0; r < state.competition.competition.grade.rounds; r++) {
        for (let j = 0; j < state.competition.competition.grade.judges / 3; j++) {
          for (let it = 0; it < template.rounds[(r + 1).toString()].scores[j].length; it++) {
            let item = template.rounds[(r + 1).toString()].scores[j][it];
            if (item.entryCompetition === entry.entryCompetition) {
              allRel.push(item);
            }
          }
        }
      }

      for (let ar of allRel) {
        ar[`nq`] = true;
      }
    } else {
      let allRel = [];
      for (let r = 0; r < state.competition.competition.grade.rounds; r++) {
        for (let j = 0; j < state.competition.competition.grade.judges / 3; j++) {
          for (let it = 0; it < template.rounds[(r + 1).toString()].scores[j].length; it++) {
            let item = template.rounds[(r + 1).toString()].scores[j][it];
            if (item.entryCompetition === entry.entryCompetition) {
              allRel.push(item);
            }
          }
        }
      }

      for (let ar of allRel) {
        ar[`nq`] = false;
      }
    }
    ind++;
  }

  return template;
}

function assignSingleRoundPlacement(template, ro, judgeIndex, state) {
  let scores = JSON.parse(JSON.stringify(template.rounds['1'].scores[0]));
  let sorted = scores.sort(
    (a, b) => parseFloat(a[`totalRound1Rank`]) - parseFloat(b[`totalRound1Rank`])
  );

  let wmh = scores.filter((item) => item.wmh).length;
  let grade = state.competition.competition.grade;
  let comp = state.competition.competition;

  let ageTitle = '';

  for (let col of grade.columns) {
    if (col.id.toString() === comp.column) {
      ageTitle = col.title.toLowerCase();
    }
  }

  //let lowest = sorted[sorted.length - 1].finalRank;
  let count = sorted.length;
  let afterTwenty = count - 20;

  let additional = 0;
  if (afterTwenty > 0) {
    additional = Math.ceil(afterTwenty / 10);
  }
  let base = 5;

  /*

    if(ageTitle.indexOf('senior men') > -1 || ageTitle.indexOf('senior ladies') > -1) {
        if(afterTwenty > 0) {
            additional = Math.ceil(afterTwenty / 5);
        }
        base = 5;
    }
*/

  let highestIndex = base + additional + wmh;
  highestIndex = 3;

  if (sorted.length < 5) {
    highestIndex = sorted.length;
  } else if (sorted.length === 5) {
    highestIndex = 5;
  } else if (sorted.length > 5) {
    highestIndex = Math.ceil(sorted.length * 0.5);
  }

  //console.log("HIGHSt", highestIndex);
  let ind = 0;
  for (let entry of sorted) {
    //console.log("EMT", entry.finalRank);

    if (ind + 1 <= highestIndex) {
      let allRel = [];
      for (let r = 0; r < state.competition.competition.grade.rounds; r++) {
        for (let j = 0; j < state.competition.competition.grade.judges / 3; j++) {
          for (let it = 0; it < template.rounds[(r + 1).toString()].scores[j].length; it++) {
            let item = template.rounds[(r + 1).toString()].scores[j][it];
            if (item.entryCompetition === entry.entryCompetition) {
              allRel.push(item);
            }
          }
        }
      }

      for (let ar of allRel) {
        ar[`wq`] = true;
      }
    } else {
      let allRel = [];
      for (let r = 0; r < state.competition.competition.grade.rounds; r++) {
        for (let j = 0; j < state.competition.competition.grade.judges / 3; j++) {
          for (let it = 0; it < template.rounds[(r + 1).toString()].scores[j].length; it++) {
            let item = template.rounds[(r + 1).toString()].scores[j][it];
            if (item.entryCompetition === entry.entryCompetition) {
              allRel.push(item);
            }
          }
        }
      }

      for (let ar of allRel) {
        ar[`wq`] = false;
      }
    }
    ind++;
  }

  ind = 0;
  for (let entry of sorted) {
    let rank = entry.finalRank;

    if (
      sorted.filter((item) => item.totalRound1Rank === entry.totalRound1Rank && item.wq === true)
        .length > 0
    ) {
      let allRel = [];
      for (let r = 0; r < state.competition.competition.grade.rounds; r++) {
        for (let j = 0; j < state.competition.competition.grade.judges / 3; j++) {
          for (let it = 0; it < template.rounds[(r + 1).toString()].scores[j].length; it++) {
            let item = template.rounds[(r + 1).toString()].scores[j][it];
            if (item.entryCompetition === entry.entryCompetition) {
              allRel.push(item);
            }
          }
        }
      }

      for (let ar of allRel) {
        ar[`wq`] = true;
      }
    }

    ind++;
  }

  return template;
}

function assignPlacement(template, ro, judgeIndex, state) {
  let scores = JSON.parse(JSON.stringify(template.rounds['1'].scores[0]));
  let sorted = scores.sort((a, b) => parseFloat(a[`finalRank`]) - parseFloat(b[`finalRank`]));

  let wmh = scores.filter((item) => item.wmh).length;
  let grade = state.competition.competition.grade;
  let comp = state.competition.competition;

  let ageTitle = '';

  for (let col of grade.columns) {
    if (col.id.toString() === comp.column) {
      ageTitle = col.title.toLowerCase();
    }
  }

  //let lowest = sorted[sorted.length - 1].finalRank;
  let count = sorted.length;
  let afterTwenty = count - 20;

  let additional = 0;
  if (afterTwenty > 0) {
    additional = Math.ceil(afterTwenty / 10);
  }
  let base = 6;

  /*

    if(ageTitle.indexOf('senior men') > -1 || ageTitle.indexOf('senior ladies') > -1) {
        if(afterTwenty > 0) {
            additional = Math.ceil(afterTwenty / 5);
        }
        base = 5;
    }
*/

  let highestIndex = base + additional + wmh;
  highestIndex = 3;

  if (sorted.length < 5) {
    highestIndex = sorted.length;
  } else if (sorted.length === 5) {
    highestIndex = 5;
  } else if (sorted.length > 5) {
    highestIndex = Math.ceil(sorted.length * 0.5);
  }

  //console.log("HIGHSt", highestIndex);
  let ind = 0;
  for (let entry of sorted) {
    //console.log("EMT", entry.finalRank);

    if (ind + 1 <= highestIndex) {
      let allRel = [];
      for (let r = 0; r < state.competition.competition.grade.rounds; r++) {
        for (let j = 0; j < state.competition.competition.grade.judges / 3; j++) {
          for (let it = 0; it < template.rounds[(r + 1).toString()].scores[j].length; it++) {
            let item = template.rounds[(r + 1).toString()].scores[j][it];
            if (item.entryCompetition === entry.entryCompetition) {
              allRel.push(item);
            }
          }
        }
      }

      for (let ar of allRel) {
        ar[`wq`] = true;
      }
    } else {
      let allRel = [];
      for (let r = 0; r < state.competition.competition.grade.rounds; r++) {
        for (let j = 0; j < state.competition.competition.grade.judges / 3; j++) {
          for (let it = 0; it < template.rounds[(r + 1).toString()].scores[j].length; it++) {
            let item = template.rounds[(r + 1).toString()].scores[j][it];
            if (item.entryCompetition === entry.entryCompetition) {
              allRel.push(item);
            }
          }
        }
      }

      for (let ar of allRel) {
        ar[`wq`] = false;
      }
    }
    ind++;
  }

  ind = 0;
  for (let entry of sorted) {
    let rank = entry.finalRank;

    if (
      sorted.filter(
        (item) => item.finalRank === entry.finalRank && item._id !== entry._id && item.wq === true
      ).length > 0
    ) {
      let allRel = [];
      for (let r = 0; r < state.competition.competition.grade.rounds; r++) {
        for (let j = 0; j < state.competition.competition.grade.judges / 3; j++) {
          for (let it = 0; it < template.rounds[(r + 1).toString()].scores[j].length; it++) {
            let item = template.rounds[(r + 1).toString()].scores[j][it];
            if (item.entryCompetition === entry.entryCompetition) {
              allRel.push(item);
            }
          }
        }
      }

      for (let ar of allRel) {
        ar[`wq`] = true;
      }
    }

    ind++;
  }

  return template;
}

function assignWq(template, ro, judgeIndex, state) {
  let scores = JSON.parse(
    JSON.stringify(template.rounds['3'].scores[judgeIndex].filter((item) => item.recall))
  );
  let sorted = scores.sort((a, b) => parseFloat(a[`finalRank`]) - parseFloat(b[`finalRank`]));

  let wmh = scores.filter((item) => item.wmh).length;
  let grade = state.competition.competition.grade;
  let comp = state.competition.competition;

  let ageTitle = '';

  for (let col of grade.columns) {
    if (col.id.toString() === comp.column) {
      ageTitle = col.title.toLowerCase();
    }
  }

  //let lowest = sorted[sorted.length - 1].finalRank;
  let count = sorted.length;
  let afterTwenty = count - 20;

  let additional = 0;
  if (afterTwenty > 0) {
    additional = Math.ceil(afterTwenty / 10);
  }
  let base = 5;

  /*

    if(ageTitle.indexOf('senior men') > -1 || ageTitle.indexOf('senior ladies') > -1) {
        if(afterTwenty > 0) {
            additional = Math.ceil(afterTwenty / 5);
        }
        base = 5;
    }
*/

  let highestIndex = base + additional + wmh;

  //console.log("HIGHSt", highestIndex);
  let ind = 0;
  for (let entry of sorted) {
    //console.log("EMT", entry.finalRank);

    if (ind <= highestIndex && entry['recall']) {
      let allRel = [];
      for (let r = 0; r < state.competition.competition.grade.rounds; r++) {
        for (let j = 0; j < state.competition.competition.grade.judges / 3; j++) {
          for (let it = 0; it < template.rounds[(r + 1).toString()].scores[j].length; it++) {
            let item = template.rounds[(r + 1).toString()].scores[j][it];
            if (item.entryCompetition === entry.entryCompetition) {
              allRel.push(item);
            }
          }
        }
      }

      for (let ar of allRel) {
        ar[`wq`] = true;
      }
    } else {
      let allRel = [];
      for (let r = 0; r < state.competition.competition.grade.rounds; r++) {
        for (let j = 0; j < state.competition.competition.grade.judges / 3; j++) {
          for (let it = 0; it < template.rounds[(r + 1).toString()].scores[j].length; it++) {
            let item = template.rounds[(r + 1).toString()].scores[j][it];
            if (item.entryCompetition === entry.entryCompetition) {
              allRel.push(item);
            }
          }
        }
      }

      for (let ar of allRel) {
        ar[`wq`] = false;
      }
    }
    ind++;
  }

  return template;
}

function assignTwoRoundRank(template, ro, judgeIndex, state) {
  let scores = JSON.parse(JSON.stringify(template.rounds[ro].scores[0]));
  let sorted = scores.sort(
    (a, b) => parseFloat(b[`finalTwoRoundIp`]) - parseFloat(a[`finalTwoRoundIp`])
  );
  let ind = 0;
  for (let entry of sorted) {
    let i = sorted.filter((rel) => rel[`finalTwoRoundIp`]).indexOf(entry);
    if (parseInt(ro) === 2) {
      //i = sorted.filter(rel => rel["finalTwoRoundIp"]).indexOf(entry);
    }

    entry[`totalTwoRoundRank`] = i + 1;

    let allRel = [];
    for (let r = 0; r < state.competition.competition.grade.rounds; r++) {
      for (let j = 0; j < state.competition.competition.grade.judges / 3; j++) {
        for (let it = 0; it < template.rounds[(r + 1).toString()].scores[j].length; it++) {
          let item = template.rounds[(r + 1).toString()].scores[j][it];
          if (item.entryCompetition === entry.entryCompetition) {
            allRel.push(item);
          }
        }
      }
    }

    if (i > 0) {
      if (entry[`finalTwoRoundIp`] === sorted[i - 1][`finalTwoRoundIp`]) {
        entry[`totalTwoRoundRank`] = sorted[i - 1][`totalTwoRoundRank`];
      }
    }

    let rel = template.rounds[ro].scores[0].filter((item) => item._id === entry._id);
    if (rel[0]) {
      rel[0][`totalTwoRoundRank`] = entry[`totalTwoRoundRank`];
    }

    let totalScore = entry[`finalTwoRoundIp`];
    let firstAppearance = -1;
    let ties = sorted.filter((rel2, index) => {
      if (rel2[`finalTwoRoundIp`] === rel[0][`finalTwoRoundIp`] && firstAppearance === -1) {
        firstAppearance = index;
      }
      return rel2[`finalTwoRoundIp`] === totalScore;
    });
    rel[0][`totalTwoRoundRank`] = firstAppearance + 1;

    if (ties.length > 0) {
      let tiePoints = [];
      let tieSum = 0;
      let firstPoint = firstAppearance;
      for (let o = 0; o < ties.length; o++) {
        tiePoints.push(irishPoints[firstPoint + o]);
        tieSum += irishPoints[firstPoint + o];
      }
      //console.debug("TIE POINTS", tieSum, tiePoints);
      //rel[0][`totalRound${ro}Ip`] = (tieSum / tiePoints.length).toFixed(2);
      rel[0][`totalTwoRoundRank`] = firstAppearance + 1;
    } else {
      //rel[0][`totalRound${ro}Ip`]  = irishPoints[ind];
      rel[0][`totalTwoRoundRank`] = ind + 1;
    }

    for (let ar of allRel) {
      ar[`totalTwoRoundRank`] = rel[0][`totalTwoRoundRank`];
    }

    ind++;
  }

  return template;
}

function assignTotalRoundRanks(template, ro, judgeIndex, state) {
  let scores = JSON.parse(JSON.stringify(template.rounds[ro].scores[judgeIndex]));
  let sorted = scores.sort(
    (a, b) => parseFloat(b[`totalRound${ro}Ip`]) - parseFloat(a[`totalRound${ro}Ip`])
  );
  let ind = 0;
  for (let entry of sorted) {
    let i = sorted.filter((rel) => rel[`totalRound${ro}Ip`]).indexOf(entry);
    if (parseInt(ro) === 2) {
      //i = sorted.filter(rel => rel["round1Ip"] + rel["round2Ip"]).indexOf(entry);
    }

    entry[`totalRound${ro}Rank`] = i + 1;

    if (i > 0) {
      if (entry[`totalRound${ro}Ip`] === sorted[i - 1][`totalRound${ro}Ip`]) {
        entry[`totalRound${ro}Rank`] = sorted[i - 1][`totalRound${ro}Rank`];
      }
    }

    let rel = template.rounds[ro].scores[judgeIndex].filter((item) => item._id === entry._id);
    if (rel[0]) {
      rel[0][`totalRound${ro}Rank`] = entry[`totalRound${ro}Rank`];
    }

    let allRel = [];
    for (let r = 0; r < state.competition.competition.grade.rounds; r++) {
      for (let j = 0; j < state.competition.competition.grade.judges / 3; j++) {
        for (let it = 0; it < template.rounds[(r + 1).toString()].scores[j].length; it++) {
          let item = template.rounds[(r + 1).toString()].scores[j][it];
          if (item.entryCompetition === entry.entryCompetition) {
            allRel.push(item);
          }
        }
      }
    }

    let totalScore = entry[`totalRound${ro}Ip`];
    let firstAppearance = -1;
    let ties = sorted.filter((rel2, index) => {
      if (rel2[`totalRound${ro}Ip`] === rel[0][`totalRound${ro}Ip`] && firstAppearance === -1) {
        firstAppearance = index;
      }
      return rel2[`totalRound${ro}Ip`] === totalScore;
    });
    entry[`totalRound${ro}Rank`] = firstAppearance + 1;

    if (ties.length > 0) {
      let tiePoints = [];
      let tieSum = 0;
      let firstPoint = firstAppearance;
      for (let o = 0; o < ties.length; o++) {
        tiePoints.push(irishPoints[firstPoint + o]);
        tieSum += irishPoints[firstPoint + o];
      }
      //console.debug("TIE POINTS", tieSum, tiePoints);
      //rel[0][`totalRound${ro}Ip`] = (tieSum / tiePoints.length).toFixed(2);
      rel[0][`totalRound${ro}Rank`] = firstAppearance + 1;
    } else {
      //rel[0][`totalRound${ro}Ip`]  = irishPoints[ind];
      rel[0][`totalRound${ro}Rank`] = ind + 1;
    }

    for (let ar of allRel) {
      ar[`totalRound${ro}Rank`] = rel[0][`totalRound${ro}Rank`];
    }
    ind++;
  }

  return template;
}

function assignSingleRoundRanks(template, ro, state, entries) {
  let sorted = entries.sort(
    (a, b) => parseFloat(b[`totalRound${ro}Score`]) - parseFloat(a[`totalRound${ro}Score`])
  );
  for (let j = 0; j < template.rounds[ro].judges.length; j++) {
    for (let i = 0; i < template.rounds[ro].scores[j].length; i++) {
      let item = template.rounds[ro].scores[j][i];
      //console.log("TEST", item, action.payload.entry, item._id === action.payload.entry._id);

      for (let en = 0; en < entries.length; en++) {
        if (item.entryCompetition === entries[en].entryCompetition) {
          entries[en]['totalRound' + ro + 'Rank'] = en + 1;
          template.rounds[ro].scores[j][i]['totalRound' + ro + 'Score'] =
            entries[en]['totalRound' + ro + 'Score'];
          template.rounds[ro].scores[j][i]['totalRound' + ro + 'Ip'] =
            entries[en]['totalRound' + ro + 'Ip'];
          //template.rounds[ro].scores[j][i]["totalRound" + ro + "Rank"] = en + 1;
        }
      }
    }
  }

  sorted = entries.sort(
    (a, b) => parseFloat(b[`totalRound${ro}Ip`]) - parseFloat(a[`totalRound${ro}Ip`])
  );

  for (let j = 0; j < template.rounds[ro].judges.length; j++) {
    for (let i = 0; i < template.rounds[ro].scores[j].length; i++) {
      let item = template.rounds[ro].scores[j][i];
      //console.log("TEST", item, action.payload.entry, item._id === action.payload.entry._id);

      for (let en = 0; en < entries.length; en++) {
        if (item.entryCompetition === entries[en].entryCompetition) {
          entries[en]['totalRound' + ro + 'Rank'] = en + 1;
          //template.rounds[ro].scores[j][i]["totalRound" + ro + "Score"] = entries[en]["totalRound" + ro + "Score"];
          template.rounds[ro].scores[j][i]['totalRound' + ro + 'Rank'] = en + 1;
        }
      }
    }
  }

  return template;
}

function updateEntryScore(template, state, entry, round, key, val) {
  let allRel = [];
  for (let r = 0; r < state.competition.competition.grade.rounds; r++) {
    for (let j = 0; j < state.competition.competition.grade.scoring.judge_count[r]; j++) {
      for (let it = 0; it < template.rounds[(r + 1).toString()].scores[j].length; it++) {
        let item = template.rounds[(r + 1).toString()].scores[j][it];
        if (item.entryCompetition === entry.entryCompetition) {
          allRel.push(item);
        }
      }
    }
  }

  for (let ar of allRel) {
    ar[key] = val;
  }

  return template;
}

function convertTo300Round1(template, ro, j, state) {
  let shadow = [];

  let scores = JSON.parse(JSON.stringify(template.rounds[ro].scores[j]));
  let sorted = scores.sort(
    (a, b) => parseFloat(b[`totalRound${ro}Score`]) - parseFloat(a[`totalRound${ro}Score`])
  );
  let ind = 0;
  for (let entry of sorted) {
    let i = sorted.filter((rel) => rel[`totalRound${ro}Score`]).indexOf(entry);
    if (parseInt(ro) === 2) {
      // i = sorted.filter(rel => rel["round1Score"] + rel["round2Score"]).indexOf(entry);
    }

    entry[`totalRound${ro}Rank`] = i + 1;

    if (i > 0) {
      if (entry[`totalRound${ro}Score`] === sorted[i - 1][`totalRound${ro}Score`]) {
        entry[`totalRound${ro}Rank`] = sorted[i - 1][`totalRound${ro}Rank`];
      }
    }

    let rel = template.rounds[ro].scores[j].filter((item) => item._id === entry._id);
    if (rel[0]) {
      rel[0][`totalRound${ro}Rank`] = entry[`totalRound${ro}Rank`];
    }

    let totalScore = entry[`totalRound${ro}Score`];
    let firstAppearance = -1;
    let ties = sorted.filter((rel2, index) => {
      if (
        rel2[`totalRound${ro}Score`] === rel[0][`totalRound${ro}Score`] &&
        firstAppearance === -1
      ) {
        firstAppearance = index;
      }
      return rel2[`totalRound${ro}Score`] === totalScore;
    });
    entry[`totalRound${ro}Rank`] = firstAppearance + 1;

    // console.log("TIES", ties);
    //console.debug("FINALL SCORE", irishPoints[firstAppearance]);

    if (ties.length > 0) {
      let tiePoints = [];
      let tieSum = 0;
      let firstPoint = firstAppearance;
      for (let o = 0; o < ties.length; o++) {
        tiePoints.push(irishPoints[firstPoint + o]);
        tieSum += irishPoints[firstPoint + o];
      }
      rel[0][`totalRound${ro}Ip`] = parseFloat((tieSum / tiePoints.length).toFixed(2));

      let r1 = 0;
      let r2 = 0;

      let rel6 = template.rounds['1'].scores[0].filter(
        (item) => item.entryCompetition === entry.entryCompetition
      );
      let rel7 = template.rounds['2'].scores[0].filter(
        (item) => item.entryCompetition === entry.entryCompetition
      );
      let rel8 = template.rounds['3'].scores[0].filter(
        (item) => item.entryCompetition === entry.entryCompetition
      );

      if (ro === '1') {
        rel[0][`finalTwoRoundIp`] = rel[0][`totalRound${ro}Ip`] + rel7[0]['totalRound2Ip'];
        try {
          rel[0]['finalIp'] =
            rel[0]['totalRound1Ip'] + rel7[0]['totalRound2Ip'] + rel8[0]['totalRound3Ip'];
        } catch (e) {
          console.log('ERR', e);
        }
      } else if (ro === '2') {
        rel[0][`finalTwoRoundIp`] = rel[0][`totalRound${ro}Ip`] + rel6[0]['totalRound1Ip'];
        try {
          rel[0]['finalIp'] =
            rel6[0]['totalRound1Ip'] + rel[0]['totalRound2Ip'] + rel8[0]['totalRound3Ip'];
        } catch (e) {
          console.log('ERR', e);
        }
      } else if (ro === '3') {
        rel[0]['finalIp'] =
          rel6[0]['totalRound1Ip'] + rel7[0]['totalRound2Ip'] + rel[0]['totalRound3Ip'];
      }

      //rel[0][`finalTwoRoundIp`] = rel6[0][`totalRound1Ip`] + rel7[0]['totalRound2Ip'];
    } else {
      rel[0][`totalRound${ro}Ip`] = parseFloat(irishPoints[ind]);
      rel[0][`totalRound${ro}Rank`] = parseFloat(ind + 1);
    }

    let allRel = [];
    for (let r = 0; r < state.competition.competition.grade.rounds; r++) {
      for (let j = 0; j < state.competition.competition.grade.judges; j++) {
        for (let it = 0; it < template.rounds[(r + 1).toString()].scores[j].length; it++) {
          let item = template.rounds[(r + 1).toString()].scores[j][it];
          if (item.entryCompetition === entry.entryCompetition) {
            allRel.push(item);
          }
        }
      }
    }

    for (let ar of allRel) {
      ar[`totalRound${ro}Ip`] = rel[0][`totalRound${ro}Ip`];
      ar[`totalRound${ro}Rank`] = rel[0][`totalRound${ro}Rank`];
      if (ro !== '3') {
        ar['finalTwoRoundIp'] = rel[0]['finalTwoRoundIp'];
      }
      ar['finalIp'] = rel[0]['finalIp'];
    }
  }

  return template;
  //return template;
}

function convertTo300Final(template, state, entries) {
  for (const entry of entries) {
    let finals = {
      finalRound1Ip: 0,
      finalRound2Ip: 0,
      finalRound3Ip: 0,
      finalScore: 0,
      finalIp: 0,
      finalRank: 0,
      finalTwoRoundIp: 0,
      finalTwoRoundRank: 0
    };

    for (let r = 0; r < state.competition.competition.grade.rounds; r++) {
      let relevant = template.rounds[(r + 1).toString()].scores[0].filter(
        (it) => it.entryCompetition === entry.entryCompetition
      );
      for (const rel in relevant) {
        finals[`finalRound${(r + 1).toString()}Ip`] +=
          relevant[rel][`totalRound${(r + 1).toString()}Ip`];
        finals[`finalIp`] += relevant[rel][`totalRound${(r + 1).toString()}Ip`];
        if (r < 2) {
          if (!isNaN(relevant[rel][`totalRound${(r + 1).toString()}Ip`])) {
            finals[`finalTwoRoundIp`] += parseFloat(
              relevant[rel][`totalRound${(r + 1).toString()}Ip`]
            );
          }
        }
      }
    }

    for (let r = 0; r < state.competition.competition.grade.rounds; r++) {
      for (let j = 0; j < state.competition.competition.grade.judges / 3; j++) {
        let relevant = template.rounds[(r + 1).toString()].scores[j].filter(
          (it) => it.entryCompetition === entry.entryCompetition
        );

        for (let rel in relevant) {
          relevant[rel][`finalIp`] = finals[`finalIp`];
          relevant[rel][`finalTwoRoundIp`] = finals[`finalTwoRoundIp`];
          relevant[rel][`finalRound${(r + 1).toString()}Ip`] =
            finals[`totalRound${(r + 1).toString()}Ip`];
        }
      }
    }
    // console.log("ENTRY", entry);
    //console.log("FINALS", finals);
  }

  return template;
}

function assignJudgeTotals(template, ro, j, state) {
  let scores = JSON.parse(JSON.stringify(template.rounds[ro].scores[j]));
  let sorted = scores.sort(
    (a, b) =>
      parseFloat(b[`round1Score`] + b[`round2Score`] + b[`round3Score`]) -
      parseFloat(a[`round1Score`] + a[`round2Score`] + a[`round3Score`])
  );

  let rounds = ['1', '2', '3'];
  for (let entry of sorted) {
    // Add up total score for THIS JUDGE ON THIS DANCER
    // and save it to ALL entryscores matching this dancer AND this judge, NOT round
    let judgeTotalScore = 0;
    let rel = template.rounds['1'].scores[j].filter(
      (item) => item.entryCompetition === entry.entryCompetition
    );
    let rel2 = template.rounds['2'].scores[j].filter(
      (item) => item.entryCompetition === entry.entryCompetition
    );
    let rel3 = template.rounds['3'].scores[j].filter(
      (item) => item.entryCompetition === entry.entryCompetition
    );
    let r1, r2, r3;
    if (rel.length > 0) {
      r1 = rel[0];
    }
    if (rel2.length > 0) {
      r2 = rel2[0];
    }
    if (rel3.length > 0) {
      r3 = rel3[0];
    }

    if (r1 && r2 && r3) {
      entry.judgeTotalScore = entry.round1Score + entry.round2Score + entry.round3Score;
      r1.judgeTotalScore = entry.judgeTotalScore;
      r2.judgeTotalScore = entry.judgeTotalScore;
      r3.judgeTotalScore = entry.judgeTotalScore;
    }
  }

  return template;
}

function assignJudgeFinals(template, ro, j, state) {
  let scores = JSON.parse(JSON.stringify(template.rounds['1'].scores[j]));
  let sorted = scores.sort(
    (a, b) =>
      parseFloat(b[`round1Score`] + b[`round2Score`] + b[`round3Score`]) -
      parseFloat(a[`round1Score`] + a[`round2Score`] + a[`round3Score`])
  );

  let rounds = ['1', '2', '3'];
  for (let entry of sorted) {
    let ind = 0;
    // Add up total score for THIS JUDGE ON THIS DANCER
    // and save it to ALL entryscores matching this dancer AND this judge, NOT round
    let judgeTotalScore = 0;
    let rel = template.rounds['1'].scores[j].filter(
      (item) => item.entryCompetition === entry.entryCompetition
    );
    let rel2 = template.rounds['2'].scores[j].filter(
      (item) => item.entryCompetition === entry.entryCompetition
    );
    let rel3 = template.rounds['3'].scores[j].filter(
      (item) => item.entryCompetition === entry.entryCompetition
    );
    let r1, r2, r3;
    if (rel.length > 0) {
      r1 = rel[0];
    }
    if (rel2.length > 0) {
      r2 = rel2[0];
    }
    if (rel3.length > 0) {
      r3 = rel3[0];
    }

    let i = sorted.filter((rel) => rel[`judgeTotalScore`]).indexOf(entry);

    entry[`judgeTotalRank`] = i + 1;

    if (i > 0) {
      if (entry[`judgeTotalScore`] === sorted[i - 1][`judgeTotalScore`]) {
        entry[`judgeTotalRank`] = sorted[i - 1][`judgeTotalRank`];
      }
    }

    let firstAppearance = -1;
    let ties = sorted.filter((rel2, index) => {
      if (rel2[`judgeTotalScore`] === r1[`judgeTotalScore`] && firstAppearance === -1) {
        firstAppearance = index;
      }
      return rel2[`judgeTotalScore`] === r1.judgeTotalScore;
    });
    entry[`judgeTotalRank`] = firstAppearance + 1;
    entry['judgeTied'] = ties.length <= 1;

    if (r1 && r2 && r3) {
      r1.judgeTotalRank = entry.judgeTotalRank;
      r2.judgeTotalRank = entry.judgeTotalRank;
      r3.judgeTotalRank = entry.judgeTotalRank;

      r1.judgeTied = entry.judgeTied;
      r2.judgeTied = entry.judgeTied;
      r3.judgeTied = entry.judgeTied;
    }
    if (ties.length > 0) {
      let tiePoints = [];
      let tieSum = 0;
      let firstPoint = firstAppearance;
      for (let o = 0; o < ties.length; o++) {
        tiePoints.push(irishPoints[firstPoint + o]);
        tieSum += irishPoints[firstPoint + o];
      }
      entry.judgeFinalIp = parseFloat((tieSum / tiePoints.length).toFixed(2));

      //rel[0][`finalTwoRoundIp`] = rel6[0][`totalRound1Ip`] + rel7[0]['totalRound2Ip'];
    } else {
      entry.judgeFinalIp = parseFloat(irishPoints[ind]);
    }

    if (r1 && r2 && r3) {
      r1.judgeFinalIp = entry.judgeFinalIp;
      r2.judgeFinalIp = entry.judgeFinalIp;
      r3.judgeFinalIp = entry.judgeFinalIp;
    }

    ind++;
  }

  return template;
}

function assignAllJudgeFinals(template, ro, j, state) {
  let scores = JSON.parse(JSON.stringify(template.rounds['1'].scores[0]));

  // set final IP, then sort by final ip and assign rank
  for (let entry of scores) {
    let judge1 = template.rounds['1'].scores[0].filter(
      (item) => item.entryCompetition === entry.entryCompetition
    );
    let judge2 = template.rounds['1'].scores[1].filter(
      (item) => item.entryCompetition === entry.entryCompetition
    );
    let judge3 = template.rounds['1'].scores[2].filter(
      (item) => item.entryCompetition === entry.entryCompetition
    );
    let j1, j2, j3;
    if (judge1.length > 0) {
      j1 = judge1[0];
    }
    if (judge2.length > 0) {
      j2 = judge2[0];
    }
    if (judge3.length > 0) {
      j3 = judge3[0];
    }

    entry.finalIp = j1.judgeFinalIp + j2.judgeFinalIp + j3.judgeFinalIp;
    if (j1 && j2 && j3) {
      j1.finalIp = entry.finalIp;
      j2.finalIp = entry.finalIp;
      j3.finalIp = entry.finalIp;
    }
  }
  scores = JSON.parse(JSON.stringify(template.rounds['1'].scores[0]));
  let sorted = scores.sort((a, b) => b.finalIp - a.finalIp);

  let ind = 0;
  for (let entry of sorted) {
    let i = sorted.filter((rel) => rel[`finalIp`]).indexOf(entry);
    if (parseInt(ro) === 2) {
      // i = sorted.filter(rel => rel["round1Score"] + rel["round2Score"]).indexOf(entry);
    }

    entry[`finalRank`] = i + 1;

    if (i > 0) {
      if (entry[`finalIp`] === sorted[i - 1][`finalIp`]) {
        entry[`finalRank`] = sorted[i - 1][`finalRank`];
      }
    }

    let rel = template.rounds[ro].scores[j].filter((item) => item._id === entry._id);
    if (rel[0]) {
      rel[0][`finalRank`] = entry[`finalRank`];
    }

    let totalScore = entry[`finalIp`];
    let firstAppearance = -1;
    let ties = sorted.filter((rel2, index) => {
      if (rel2) {
        if (rel2[`finalIp`] === totalScore && firstAppearance === -1) {
          firstAppearance = index;
        }
      }
      return rel2 ? rel2[`finalIp`] === totalScore : false;
    });
    entry[`finalRank`] = firstAppearance + 1;
    entry['tied'] = ties.length > 1;
    // console.log("TIES", ties);
    //console.debug("FINALL SCORE", irishPoints[firstAppearance]);

    let allRel = [];
    for (let r = 0; r < state.competition.competition.grade.rounds; r++) {
      for (let j = 0; j < state.competition.competition.grade.judges / 3; j++) {
        for (let it = 0; it < template.rounds[(r + 1).toString()].scores[j].length; it++) {
          let item = template.rounds[(r + 1).toString()].scores[j][it];
          if (item.entryCompetition === entry.entryCompetition) {
            allRel.push(item);
          }
        }
      }
    }

    for (let ar of allRel) {
      ar[`finalRank`] = entry[`finalRank`];
      ar[`tied`] = entry[`tied`];
      //ar['finalTwoRoundIp'] = rel[0]['finalTwoRoundIp'];
    }
  }

  return template;
}

function convertTo300FinalRank(template, ro, j, state) {
  let scores = JSON.parse(JSON.stringify(template.rounds['3'].scores[0]));
  let sorted = scores.sort((a, b) => parseFloat(b[`finalIp`]) - parseFloat(a[`finalIp`]));
  let ind = 0;
  for (let entry of sorted) {
    let i = sorted.filter((rel) => rel[`finalIp`]).indexOf(entry);
    if (parseInt(ro) === 2) {
      // i = sorted.filter(rel => rel["round1Score"] + rel["round2Score"]).indexOf(entry);
    }

    entry[`finalRank`] = i + 1;

    if (i > 0) {
      if (entry[`finalIp`] === sorted[i - 1][`finalIp`]) {
        entry[`finalRank`] = sorted[i - 1][`finalRank`];
      }
    }

    let rel = template.rounds[ro].scores[j].filter((item) => item._id === entry._id);
    if (rel[0]) {
      rel[0][`finalRank`] = entry[`finalRank`];
    }

    let totalScore = entry[`finalIp`];
    let firstAppearance = -1;
    let ties = sorted.filter((rel2, index) => {
      if (rel2) {
        if (rel2[`finalIp`] === totalScore && firstAppearance === -1) {
          firstAppearance = index;
        }
      }
      return rel2 ? rel2[`finalIp`] === totalScore : false;
    });
    entry[`finalRank`] = firstAppearance + 1;

    // console.log("TIES", ties);
    //console.debug("FINALL SCORE", irishPoints[firstAppearance]);

    let allRel = [];
    for (let r = 0; r < state.competition.competition.grade.rounds; r++) {
      for (let j = 0; j < state.competition.competition.grade.judges / 3; j++) {
        for (let it = 0; it < template.rounds[(r + 1).toString()].scores[j].length; it++) {
          let item = template.rounds[(r + 1).toString()].scores[j][it];
          if (item.entryCompetition === entry.entryCompetition) {
            allRel.push(item);
          }
        }
      }
    }

    for (let ar of allRel) {
      ar[`finalRank`] = entry[`finalRank`];
      //ar['finalTwoRoundIp'] = rel[0]['finalTwoRoundIp'];
      ar['finalIp'] = entry['finalIp'];
    }
  }

  return template;
}

const events = (state = defaultState, action) => {
  switch (action.type) {
    case 'TABULATOR_REMOVED_DANCER':
      let t9 = state.competition;

      t9.entries = t9.entries.filter(item => item._id !== action.payload.entryCompetitionId);

      for (let r9 of Object.keys(t9.template.rounds)) {
        //console.log("R", r);
        for (let s9 in t9.template.rounds[r9].scores) {
            t9.template.rounds[r9].scores[s9] = t9.template.rounds[r9].scores[s9].filter(item => item.entryCompetition !== action.payload.entryCompetitionId);
          
        }
      }

      /*
        Where a dancer is being removed, we actually need to recalculate all judges/rounds scores, and placings, and resultant final scores... :(

       */
      let t9Template = state.competition.template;
      for (const judgeIndex of Array(state.competition.competition.grade.judges).keys()) {
        const ji = judgeIndex;
        for (const ro of Array(state.competition.competition.grade.rounds).keys()){
          const roString = (ro + 1).toString();
          t9Template = assignJudgeRoundRanks(t9Template, roString, ji);
          let entr = assignFullRoundScores(t9Template, roString, ji);

          t9Template = assignSingleRoundRanks(t9Template, roString, state, entr);
          t9Template = assignTotalRoundRanks(t9Template, roString, ji, state);
          if (state.competition.competition.grade.rounds > 1) {
            t9Template = assignFinals(t9Template, state, entr);
            t9Template = assignTwoRoundRank(t9Template, roString, ji, state);
            t9Template = assignRecall(t9Template, roString, ji, state);
            t9Template = assignFinalRoundRank(t9Template, roString, ji, state);
            t9Template = assignNq(t9Template, roString, ji, state);
            t9Template = assignPlacement(t9Template, roString, ji, state);
          }

          if (state.competition.competition.grade.judges === 3) {
            t9Template = convertTo300Round1(t9Template, roString, ji, state);
            t9Template = assignJudgeTotals(t9Template, roString, ji, state);
            t9Template = assignJudgeFinals(t9Template, roString, ji, state);
            t9Template = assignAllJudgeFinals(t9Template, roString, ji, state);
            t9Template = assignPlacement(t9Template, roString, ji, state);
          }

          if (state.competition.competition.grade.rounds === 1) {
            t9Template = assignSingleRoundPlacement(t9Template, roString, ji, state);
          }
        }
      }
      
      return {
        ...state,
        competition: {
          ...t9,
          template: t9Template
        }
      };
    case 'TABULATOR_CHANGED_DANCER':
      let t2 = state.competition;

      let data2 = action.payload;
      if (data2.data.entry) {
        t2.entries = t2.entries.map((item) => {
          if (item._id === data2.oldId) {
            return data2.data.entry;
          } else {
            return item;
          }
        });

        for (let r2 of Object.keys(t2.template.rounds)) {
          //console.log("R", r);
          for (let s2 in t2.template.rounds[r2].scores) {
            if (data2.data.rounds[r2].scores[s2][0]) {
              t2.template.rounds[r2].scores[s2] = t2.template.rounds[r2].scores[s2].map((item) => {
                if (item.entryCompetition === data2.oldId) {
                  return {
                    ...item,
                    entryCompetition: data2.data.entry._id
                  };
                } else {
                  return item;
                }
              });
            }
          }
        }
      }

      return {
        ...state,
        competition: t2
      };
    case 'TABULATOR_ADDED_DANCER':
      let t = state.competition;

      let data = action.payload;
      //console.log("Caught add dancer", data);
      if (data.entry) {
        t.entries.push(data.entry);

        for (let r of Object.keys(t.template.rounds)) {
          //console.log("R", r);
          for (let s in t.template.rounds[r].scores) {
            if (data.rounds[r].scores[s][0]) {
              t.template.rounds[r].scores[s].push(data.rounds[r].scores[s][0]);
            }
          }
        }
      }

      //console.log("Setting template", t);

      return {
        ...state,
        competition: t
      };
    case 'UPLOADED_SCHEDULE':
      return {
        ...state,
        event: {
          ...state.event,
          schedule: action.payload.schedule
        }
      };
    case 'UPDATE_SCORE':
      let template = state.competition.template;
      let ro = action.payload.round.toString();
      //console.log("Round", ro);
      //console.log("TM", state.competition);
      //console.log("VL", action.payload.value);
      console.log(' >> SCORING ...');
      template = addScore(
        template,
        ro,
        state,
        action.payload.entry,
        action.payload.judgeIndex,
        action.payload.value
      );
      // template = assignJudgeRoundRanks(template, ro, action.payload.judgeIndex);

      // let entries = assignFullRoundScores(template, ro, action.payload.judgeIndex);

      // template = assignSingleRoundRanks(template, ro, state, entries);

      // template = assignTotalRoundRanks(template, ro, action.payload.judgeIndex, state);
      // if (state.competition.competition.grade.rounds > 1) {
      //   template = assignFinals(template, state, entries);

      //   template = assignTwoRoundRank(template, ro, action.payload.judgeIndex, state);

      //   template = assignRecall(template, ro, action.payload.judgeIndex, state);

      //   template = assignFinalRoundRank(template, ro, action.payload.judgeIndex, state);

      //   template = assignNq(template, ro, action.payload.judgeIndex, state);
      //   //template = assignWq(template, ro, action.payload.judgeIndex, state);
      //   template = assignPlacement(template, ro, action.payload.judgeIndex, state);
      // }

      // if (state.competition.competition.grade.judges === 3) {
      //   template = convertTo300Round1(template, ro, action.payload.judgeIndex, state);
      //   //template = convertTo300Final(template, state, entries);
      //   //template = convertTo300FinalRank(template, ro, action.payload.judgeIndex, state);

      //   template = assignJudgeTotals(template, ro, action.payload.judgeIndex, state);
      //   template = assignJudgeFinals(template, ro, action.payload.judgeIndex, state);
      //   template = assignAllJudgeFinals(template, ro, action.payload.judgeIndex, state);
      //   template = assignPlacement(template, ro, action.payload.judgeIndex, state);
      // }

      // if (state.competition.competition.grade.rounds === 1) {
      //   template = assignSingleRoundPlacement(template, ro, action.payload.judgeIndex, state);
      // }

      /*



                            FINAL SCORES AND RANKS AND STUFF
             */

      /*
            entries = [];

            for(let i = 0; i < sorted.length; i++) {
                let item = sorted[i];
                let otherJudges = JSON.parse(JSON.stringify(template.rounds[ro].scores));
                let otherRoundScores = [];

                for(let o = 0; o < template.rounds[ro].scores[action.payload.judgeIndex].length; o++) {
                    let totalScore = 0;
                    let item2 = template.rounds[ro].scores[action.payload.judgeIndex][o];

                    for (let j = 0; j < otherJudges.length; j++) {

                        for (let s = 0; s < otherJudges[j].length; s++) {
                            let oItem = otherJudges[j][s];

                            if (oItem.entryCompetition === item.entryCompetition) {
                                //console.log("ITEM" ,oItem.entryCompetition === item.entryCompetition);
                                //console.log("Adding", oItem["round" + oItem.round.toString() + "Score"], "to", totalScore);


                                if (oItem["round" + oItem.round.toString() + "Score"]) {
                                    totalScore += parseFloat(oItem["round" + oItem.round.toString() + "Score"]);
                                }
                                let exists = false;

                                let ent = entries.filter(it => it.entryCompetition === oItem.entryCompetition);
                                ///console.log("En", ent);
                                if (ent.length === 0) {
                                    entries.push(oItem);
                                } else {
                                    let ind = 0;
                                    let ent = entries.filter((it, entryIndex) => {
                                        if (it.entryCompetition === oItem.entryCompetition) {
                                            ind = entryIndex;
                                        }
                                    });
                                    //console.log("Exists at index", ind, totalScore);
                                    entries[ind]["totalRound" + ro + "Score"] = totalScore;
                                    item2["totalRound" + ro + "Score"] = totalScore;
                                }
                            }
                        }


                    }

                    console.log("TOTAL SCORE", totalScore);

                    if (item.entryCompetition === item2.entryCompetition) {
                        //template.rounds[ro].scores[action.payload.judgeIndex][o]["totalRound" + ro + "Score"] = totalScore;
                        item2["totalRound" + ro + "Score"] = totalScore;
                    }
                }
            }

            sorted = entries.sort((a, b) => parseFloat(b[`totalRound${ro}Score`]) - parseFloat(a[`totalRound${ro}Score`]));
            for (let j = 0; j < template.rounds[ro].judges.length; j++) {
                for(let i = 0; i < template.rounds[ro].scores[j].length; i++) {

                    let item = template.rounds[ro].scores[j][i];
                    //console.log("TEST", item, action.payload.entry, item._id === action.payload.entry._id);

                    for (let en = 0; en < entries.length; en++){
                        if (item.entryCompetition === entries[en].entryCompetition) {
                            console.log("Found item", en, item);
                            entries[en]["totalRound" + ro + "Rank"] = en + 1;
                            template.rounds[ro].scores[j][i]["totalRound" + ro + "Score"] = entries[en]["totalRound" + ro + "Score"];
                            //template.rounds[ro].scores[j][i]["totalRound" + ro + "Rank"] = en + 1;
                        }
                    }

                }
            }

            sorted = entries.sort((a, b) => parseFloat(b[`totalRound${ro}Score`]) - parseFloat(a[`totalRound${ro}Score`]));

            for (let j = 0; j < template.rounds[ro].judges.length; j++) {
                for(let i = 0; i < template.rounds[ro].scores[j].length; i++) {

                    let item = template.rounds[ro].scores[j][i];
                    //console.log("TEST", item, action.payload.entry, item._id === action.payload.entry._id);

                    for (let en = 0; en < entries.length; en++){
                        if (item.entryCompetition === entries[en].entryCompetition) {
                            console.log("Found item", en, item);
                            entries[en]["totalRound" + ro + "Rank"] = en + 1;
                            //template.rounds[ro].scores[j][i]["totalRound" + ro + "Score"] = entries[en]["totalRound" + ro + "Score"];
                            template.rounds[ro].scores[j][i]["totalRound" + ro + "Rank"] = en + 1;
                        }
                    }

                }
            }


             */

      return {
        ...state,
        competition: {
          ...state.competition,
          competition: {
            ...state.competition.competition,
            template: template
          }
        }
      };
    case 'UPDATE_COMMENTS':
      let rel = state.competition.template.rounds[action.payload.round].scores[
        action.payload.judgeIndex
      ].filter((item, index) => {
        return item._id === action.payload.entry._id;
      });

      rel[0]['round' + action.payload.round + 'Comments'] = action.payload.value;
      return {
        ...state,
        competition: {
          ...state.competition,
          competition: {
            ...state.competition.competition,
            template: {
              ...state.competition.competition.template
            }
          }
        }
      };
    case 'SET_COMP_GENERATING':
      return {
        ...state,
        competition: {
          ...state.competition,
          competition: {
            ...state.competition.competition,
            generatingResults: true
          }
        }
      };
    case 'UPDATE_COMP_RESULT_STATUS':
      console.log('Update Status');
      return {
        ...state,
        competition: {
          ...state.competition,
          competition: {
            ...state.competition.competition,
            ...action.payload,
            generatingResults: false
          }
        }
      };
    case 'UPDATE_COMP_RESULT_SUCCESS':
      return {
        ...state,
        competition: {
          ...state.competition,
          ...action.payload,
          competition: {
            ...state.competition.competition,
            needsCalculation: false,
            generatingResults: false
          }
        }
      };
    case 'GOT_ORGANIZER_COMPETITION':
      return {
        ...state,
        organizerCompetition: action.payload.data,
        autosaved: {
          at: null,
          status: false,
          type: ''
        }
      };

    case 'GOT_ORGANIZER_SCHOOL':
      return {
        ...state,
        organizerSchool: action.payload.school,
        schoolEntries: action.payload.entries
      };
    case 'AUTOSAVED':
      return {
        ...state,
        autosaved: {
          at: new Date(),
          status: true,
          type: action.payload.type
        }
      };
    case 'MANUAL_SAVE':
      return {
        ...state,
        loaded: true,
        autosaved: {
          at: new Date(),
          status: true,
          type: action.payload.type
        },
        competition: {
          ...state.competition,
          ...action.payload.compData
          }
        };
    case 'SET_EVENT_LOAD_STATE':
      return {
          ...state,
          loaded: action.payload
      }
    case 'GOT_EVENTS_DATA':
      return {
        ...state,
        tableLoading: false,
        tableCount: action.payload.count,
        data: action.payload.data,
        hasMore: action.payload.hasMore || false,
        view: action.payload.view,
        updateCount: state.updateCount + 1
      };
    case 'COMPLETE_JUDGE_COMPETITION':
      return {
        ...state,
        competition: state.competition
          ? {
              ...state.competition,
              judgeEvent: {
                ...state.competition.judgeEvent,
                ...action.payload
              }
            }
          : defaultState.competition
      };
    case 'UPDATE_JUDGE_COMPETITION':
      //console.log
      return {
        ...state,
        competition: {
          ...state.competition,
          entries: state.competition.entries.map((entry) => {
            let rel = action.payload.data.filter((d) => d._id === entry.score._id)[0];
            if (rel) {
              return {
                ...entry,
                score: rel
              };
            } else {
              return entry;
            }
          }),
          single: action.payload.data.filter((d) => d._id === state.competition.single.score._id)[0]
            ? {
                ...state.competition.single,
                score: action.payload.data.filter(
                  (d) => d._id === state.competition.single.score._id
                )[0],
                nextUrl: action.payload.nextUrl
              }
            : state.competition.single
        }
      };
    case 'GOT_JUDGE_COMPETITION':
      return {
        ...state,
        competition: action.payload
      };
    case 'GOT_MERGE_RESPONSE':
      return {
        ...state,
        mergeResponse: action.payload.data
      };
    case 'GOT_SPLIT_RESPONSE':
      return {
        ...state,
        splitResponse: action.payload.data
      };
    case 'GOT_TABULATOR_COMPETITION':
      return {
        ...state,
        loaded: true,
        competition: action.payload,
        autosaved: {
          at: null,
          status: false,
          type: ''
        }
      };

    case 'UPDATE_JUDGE_LABEL':
      let temp = {
        ...state.competition.template,
        rounds: {
          ...state.competition.template.rounds,
          [action.payload.round.toString()]: {
            ...state.competition.template.rounds[action.payload.round.toString()],
            judges: state.competition.template.rounds[action.payload.round.toString()].judges.map(
              (item, i) => {
                if (i === action.payload.judge) {
                  return {
                    ...item,
                    label: action.payload.val
                  };
                } else {
                  return item;
                }
              }
            ),
            scores: state.competition.template.rounds[action.payload.round.toString()].scores.map(
              (item, judgeIndex) => {
                if (judgeIndex === action.payload.judge) {
                  return state.competition.template.rounds[action.payload.round.toString()].scores[
                    judgeIndex
                  ].map((sc, i) => {
                    return {
                      ...sc,
                      judgeLabel: action.payload.val
                    };
                  });
                } else {
                  return item;
                }
              }
            )
          }
        }
      };

      return {
        ...state,
        competition: {
          ...state.competition,
          template: temp
        }
      };
    case 'UPDATE_ENTRYSCORE':
      let temp4 = state.competition.template;
      temp4 = updateEntryScore(
        temp4,
        state,
        action.payload.entry,
        action.payload.round,
        action.payload.key,
        action.payload.val
      );

      return {
        ...state,
        competition: {
          ...state.competition,
          template: temp4
        }
      };

    case 'UPDATE_STANDARD_JUDGE':

      // This is called by judgeStandardTable, which is only used for SET comps. So we update the judge on the record for every round
      let rounds = [];
      let i = 0;
      let judgeIndex = parseInt(action.payload.judge);
      while (i < state.competition.competition.grade.rounds) {
        rounds.push((i + 1).toString());
        state.competition.template.rounds[(i + 1).toString()].judges =
          state.competition.template.rounds[(i + 1).toString()].judges.map((item, index) => {
            if (index === judgeIndex) {
              return action.payload.val;
            } else {
              return item;
            }
          });

        state.competition.template.rounds[(i + 1).toString()].scores =
          state.competition.template.rounds[(i + 1).toString()].scores.map((item, index) => {
            if (index === judgeIndex) {
              return state.competition.template.rounds[(i + 1).toString()].scores[index].map(
                (sc, sci) => {
                  return {
                    ...sc,
                    judge: action.payload.val
                  };
                }
              );
            } else {
              return item;
            }
          });

        i++;
      }

      console.log('Adding judge', state.competition.template);

      return {
        ...state,
        competition: state.competition
      };
    case 'UPDATE_JUDGE':
      let tmp = {
        ...state.competition.template,
        rounds: {
          ...state.competition.template.rounds,
          [action.payload.round.toString()]: {
            ...state.competition.template.rounds[action.payload.round.toString()],
            judges: state.competition.template.rounds[action.payload.round.toString()].judges.map(
              (item, i) => {
                if (i === action.payload.judge) {
                  return action.payload.val;
                } else {
                  return item;
                }
              }
            ),
            scores: state.competition.template.rounds[action.payload.round.toString()].scores.map(
              (item, judgeIndex) => {
                if (judgeIndex === action.payload.judge) {
                  return state.competition.template.rounds[action.payload.round.toString()].scores[
                    judgeIndex
                  ].map((sc, i) => {
                    return {
                      ...sc,
                      judge: action.payload.val
                    };
                  });
                } else {
                  return item;
                }
              }
            )
          }
        }
      };

      return {
        ...state,
        competition: {
          ...state.competition,
          template: tmp
        }
      };
    case 'GENERATING_STATUS_CHANGED':
      return {
        ...state,
        event: {
          ...state.event,
          generatingData: action.payload
        }
      };

    case 'UPDATE_TABULATOR_COMPETITION':
      return {
        ...state,
        competition: {
          ...state.competition,
          competition: {
            ...state.competition.competition,
            template: action.payload.competition.template
          }
        }
      };
    case 'GOT_SINGLE_EVENT':
      return {
        ...state,
        event: action.payload
      };
    case 'LOGOUT':
      return defaultState;
    case 'PATCHED_SINGLE_EVENT':
      //console.log
      return {
        ...state,
        event: {
          ...state.event,
          ...action.payload
        }
      };
    case 'PATCHED_EVENT':
      return {
        ...state,
        event: {
          ...state.event,
          ...action.payload
        }
      };
    case 'GOT_MORE_EVENTS_DATA':
      return {
        ...state,
        tableLoading: false,
        tableCount: state.tableCount + action.payload.count,
        data: state.data.concat(
          action.payload.data.filter((d) => {
            return state.data.filter((f) => f._id === d._id).length === 0;
          })
        ),
        view: action.payload.view,
        hasMore: action.payload.hasMore || false,
        updateCount: state.updateCount + 1
      };
    default:
      return state;
  }
};

export default events;
